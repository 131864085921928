import * as Tone from 'tone';
import { bpmStore, currentMusicSheetStore } from "../store.ts";
import { get } from 'svelte/store';

let bpm = get(bpmStore);
let beatPerSec = 60 / bpm;

//let measureList = get(measureListStore);

export function playAll() {
	bpm = get(bpmStore);
	beatPerSec = 60 / bpm;
	let accumulatedTime = 0;
	let musicSheet = get(currentMusicSheetStore);
	if (musicSheet.sheets.length === 0) {
	  return;
	}
	const sheet = musicSheet.sheets[0];
	const synth = new Tone.Synth().toDestination();
	const now = Tone.now();
	console.log(sheet)
	for (let measure of sheet.measures) {
	  for (let note of measure.notes) {
		// Accessing the properties of each note correctly
		const noteName = note.name;
		const octave = note.octave
		const alterationName = note.alteration;
		const figureName = note.figure.name;
		const isSilence = note.silence;
  
		console.log(noteName+alterationName);
		console.log(octave);
		console.log(figureName);
		console.log(isSilence)
		let noteDuration=parseDurationNew(figureName);
		const parsedNote = noteName.toUpperCase() + alterationName + octave.toString();
		if (isSilence==false){
			synth.triggerAttackRelease(parsedNote, noteDuration-0.1, now+accumulatedTime)
		}
		else{
			synth.triggerAttackRelease("C0", noteDuration-0.1, now+accumulatedTime)
		}
		
        accumulatedTime=accumulatedTime+noteDuration;
	  }
	}
  }
  
function parseDurationNew(duration){
	const splitArray = duration.split(/(Puntillo)/);

	// If "Puntillo" is not found, manually add an empty string
	if (splitArray.length === 1) {
	  splitArray.push("");
	}
	
	// splitArray will contain the two parts
	const noteName = splitArray[0]; // "corchea"
	const Puntillo = splitArray[1]; // "Puntillo" if it has puntillo and "" if it hasnt

	let playBackDuration=0;
	if(noteName=="Redonda"){      playBackDuration=beatPerSec*4}
	else if(noteName=="Blanca"){ playBackDuration=beatPerSec*2}
	else if(noteName=="Negra"){ playBackDuration=beatPerSec}
	else if(noteName=="Corchea"){ playBackDuration=beatPerSec/2}
	else if(noteName=="Semicorchea"){playBackDuration=beatPerSec/4}
	else if(noteName=="Fusa"){playBackDuration=beatPerSec/8}
	else if(noteName=="Garrapatea"){playBackDuration=beatPerSec/16}
	
	if(Puntillo=="Puntillo"){playBackDuration=playBackDuration+(playBackDuration/2)}
	//else if(dots=="2"){}
	return playBackDuration;
	}

/*
export function tsPlayAll() {
	bpm = get(bpmStore);
	beatPerSec = 60 / bpm;
    let accumulatedTime=0;
    let musicSheet = get(currentMusicSheetStore);
		if (musicSheet.sheets.length === 0) {
			return;
		}
		const sheet = musicSheet.sheets[0];
		console.log(sheet)
		console.log(sheet.measures)
		console.log(sheet.measures[0].notes)
		console.log(sheet.measures[0].notes[0])
		console.log(sheet.measures[0].notes[0].name)
		console.log(sheet.measures[0].notes[0].figure.name)
		const measureList = sheet.measures;
		console.log(measureList)
    const synth = new Tone.Synth().toDestination();
    const now = Tone.now()
    
    for (let i= 0; i < measureList.length; i++){
        for(let j = 0; j < measureList[i].notes; j++){
            console.log("measureList")
            console.log(measureList[i][j])
						const note = measureList[i].notes[j];
						const figure = note.figure;
            let noteDuration=parseDuration(note.duration, figure.dots);
						const parsedNote = note.name.toUpperCase() + note.octave;
						// let parsedNote =parseNote(measureList[i][j].keys[0]);
            synth.triggerAttackRelease(parsedNote, noteDuration-0.1, now+accumulatedTime)
            accumulatedTime=accumulatedTime+noteDuration;
        }
    }
}

function parseDuration(duration, dots){
let playBackDuration=0;
if(duration=="1"){      playBackDuration=beatPerSec*4}
else if(duration=="2"){ playBackDuration=beatPerSec*2}
else if(duration=="4"){ playBackDuration=beatPerSec}
else if(duration=="8"){ playBackDuration=beatPerSec/2}
else if(duration=="16"){playBackDuration=beatPerSec/4}
else if(duration=="32"){playBackDuration=beatPerSec/8}
else if(duration=="64"){playBackDuration=beatPerSec/16}

if(dots=="1"){playBackDuration=playBackDuration+(playBackDuration/2)}
//else if(dots=="2"){}
return playBackDuration;

}

function parseNote(note) {
	let array = note.split('/');
	if (array[0].length == 1) {
		array[0] = array[0].toUpperCase();
	} else if (array[0].length == 2) {
		array[0].replaceAt(0, array[0].charAt(0).toUpperCase());
	}
	return array[0] + array[1];
}

String.prototype.replaceAt = function (index, replacement) {
	return (
		this.substring(0, index) +
		replacement +
		this.substring(index + replacement.length)
	);
};
*/
<script lang="ts">
	import menu from '../assets/icons/menu.svg';
	import SideNav from '../components/SideNav.svelte';
	import { fly } from 'svelte/transition';

	import { replace } from 'svelte-spa-router';
	import { currentMusicSheetStore } from "../store";
	import SVGImage from "../components/SVGImage.svelte";
	import SheetItem from "../components/SheetItem.svelte";
	import { onMount } from "svelte";
	import {getScoredSheets} from '../lib/client/api/musicSheets';
	import LoadingSpinner from "../routes/LoadingSpinner.svelte";
	import FullAccessHomeItem from '../components/FullAccessHomeItem.svelte';
	import { ScoredSheet } from '../model/ScoredSheet';
	import { isFirstTime } from '../stores/FirstTime';
	import { get } from 'svelte/store';
	import { openModal } from 'svelte-modals'
	import DownloadAppModal from '../components/DownloadAppModal.svelte';


	let sidebar = false;


	$: sheets = [];
	$: loading = false;
	sheets = [];

	onMount(() => {
		displayDownloadOverlay();
		requestData();
	});

	async function requestData() {
		loading = true
		getScoredSheets().then(value => {
			sheets = value;
		})
		.finally(() => {
			loading = false;
		});
	}

	let active = false;

	function playLevel(selectedSheet) {
		currentMusicSheetStore.set(selectedSheet);
		replace('/editor');
	}

	function accessPaywall() {
		replace('/paywall');
	}

	function hasCompletedAllLevels() : boolean {
		if (sheets.length === 0) {
			return false;
		}
		const allLevelsUnlocked = sheets.every(sheet => {
			const result = Object.assign(new ScoredSheet(), sheet);
			return result.isPassed();
		});
		return allLevelsUnlocked;
	}

	function displayDownloadOverlay() {
		if (get(isFirstTime)) {
			openModal(DownloadAppModal, { title: "Alert", message: "This is an alert" })
			isFirstTime.set(false);
		}
	}

	$: sidebarClasses = sidebar ? "scale-75 translate-x-1/2 border border-white-100/20 overflow-hidden rounded-xl" : "";
</script>

<!--Appbar-->
<SideNav bind:show={sidebar} />
<div id="canvas" class="min-w-screen min-h-screen bg-gradient-1 relative flex flex-col">
	<div class="max-w-sm mx-auto w-full px-3 flex flex-1 flex-col py-5 gap-5 {sidebarClasses}"
			 transition:fly={{x: sidebar ? -100 : 100, opacity: 1}}>
		<!-- top section -->
		<div class=" fixed inset-x-0 top-0  bg-dark-100 z-10">
			<div class="max-w-sm mx-auto px-2 py-4 flex items-center justify-between">
				<button type="button" class="outline-none cursor-pointer" on:click={() => sidebar = !sidebar} >
					<SVGImage imagePath={menu} class="text-white w-5" />
				</button>
				<div class="flex-col cursor-pointer">
					<h1 class="text-xl font-medium text-white leading-none">Norelearn</h1>
				</div>
				<div></div>
			</div>
		</div>
		<div class="flex-1 py-10">
			<!-- middle section -->
			{#if loading}
			  <!-- Display the spinner while sheets are loading -->
			  <LoadingSpinner/>
			{:else}
				{#each sheets as sheet, i}
					<SheetItem sheet={sheet} on:play={() => playLevel(sheet)}/>
				{/each}
				<FullAccessHomeItem active={hasCompletedAllLevels()} on:play={() => accessPaywall()}/>
			{/if}
		</div>
	</div>
</div>
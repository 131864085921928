<script lang="ts">
  import { replace } from "svelte-spa-router";
  import {
    auth,
    githubAuthProvider,
    googleAuthProvider,
    twitterAuthProvider,
    signInWithPopup,

  } from "../lib/client/firebase";
  import Logo from "../assets/images/norelearn-logo.png";
  import google from "../assets/icons/google.svg";
  import twitter from "../assets/icons/twitter.svg";
  import github from "../assets/icons/github.svg";
  import { onDestroy, onMount } from "svelte";
  import { getCurrentUser } from "../lib/client/firebase";
  import { notifications } from "../toast/Notifications";
  import { demoLevelResult } from "stores/DemoLevelResult";
	import { get } from "svelte/store";

  function getLoginMessage() {
    const levelResult = get(demoLevelResult);
    if (levelResult === -1) {
      return '¡Bienvenido! Comienza tu viaje musical con nosotros';
    } else if (levelResult < 5) {
      return '¡No te desanimes! Tu camino para convertirte en músico empieza aquí.';
    } else {
      return '¡Felicidades! Has superado el primer paso para convertirte en músico. ¡Sigamos adelante juntos!';
    }
  }

  onMount(async () => {
    const user = await getCurrentUser();
    if (user !== null) {
      replace("/");
    }
  });

  onDestroy(() => {
    demoLevelResult.set(-1);
  });

  const handleGoogleLogin = () => {
    procesarFormulario(googleAuthProvider);
  };
  const handleTwitterLogin = () => {
    procesarFormulario(twitterAuthProvider);
  };
  const handleGithubLogin = () => {
    procesarFormulario(githubAuthProvider);
  };

  const procesarFormulario = async (provider) => {
    try {
      await signInWithPopup(auth, provider);
      location.reload();
    } catch (error) {
      if (error.code === 'auth/account-exists-with-different-credential') {
        notifications.red('Esta cuenta ya está vinculada con otro método de inicio de sesión. Por favor, inicia sesión usando el proveedor previamente utilizado.', 5000);
      } else {
        // Handle other errors as needed
        notifications.red('Oops! Algo ha ido mal, prueba dentro de un rato o ponte en contacto con soporte.', 5000);
        console.error('Error:', error.message);
      }
    }
  };
</script>

<div class="min-w-screen min-h-screen bg-gradient-1 relative flex flex-col">
  <div class="max-w-sm mx-auto w-full px-3 flex flex-1 flex-col py-5 gap-5">
    <div class="flex-1 flex justify-center items-center flex-row space-x-4"> <!-- Agregado space-x-4 para espaciado -->
      <div class="flex-1 flex flex-col space-y-14">
        <!-- logo -->
        <div class="flex justify-center">
          <img src={Logo} alt="logo" class="cursor-pointer" />
        </div>
        <!-- formulario -->
        <div class="space-y-6">
          <h1 class="text-xl font-medium text-center leading-none text-white-400">
            {getLoginMessage()}
          </h1>
        </div>
        <div class="space-y-4"> <!-- Agregado space-y-4 para espaciado vertical -->
          <p class="text-sm text-center text-white-400">
            Inicia sesión usando una de tus redes sociales:
          </p>
        </div>
        <div class="flex flex-row justify-center items-center space-x-4"> <!-- Agregado space-x-4 para espaciado -->
          <img
            on:click={handleGoogleLogin}
            src={google}
            alt="icono de Google"
            class="cursor-pointer w-16 h-16 hover:opacity-75"
          />
          <img
            on:click={handleTwitterLogin}
            src={twitter}
            alt="icono de Twitter"
            class="cursor-pointer w-16 h-16 hover:opacity-75"
          />
          <img
            on:click={handleGithubLogin}
            src={github}
            alt="icono de GitHub"
            class="cursor-pointer w-16 h-16 hover:opacity-75"
          />
        </div>
      </div>
    </div>
  </div>
</div>
     


<script>
  import Frame from "../assets/icons/Frame.svg";
  import RecordCircle from "../assets/icons/record-circle.svg";

  let taps = [];
  export let bpm = 0;
  let isGlowing = false;

  function handleTap() {
    const now = Date.now();

    // Store the timestamp of the tap
    taps.push(now);

    // Keep only the last 10 taps (adjust as needed)
    if (taps.length > 10) {
      taps.shift();
    }

    // Calculate BPM based on the time difference between taps
    if (taps.length >= 2) {
      const timeDifference = taps[taps.length - 1] - taps[0];
      bpm = Math.round((taps.length - 1) / (timeDifference / 60000)); // BPM calculation
    }

        // Set isGlowing to true to trigger the transition
        isGlowing = true;

      // Reset isGlowing after a delay (adjust as needed)
      setTimeout(() => {
        isGlowing = false;
      }, 200);
  }
</script>

<style>
  .glow {
    filter: brightness(2.0); /* Adjust brightness as needed */
    
    /*box-shadow: 0 0 20px rgba(240, 4, 4, 0.7);  Adjust color and spread as needed 
    border-radius: 50%;*/
  }
</style>


<div on:click="{handleTap}" class="relative flex flex-wrap justify-center items-center">
  <img src={RecordCircle} alt="record circle" class:glow={isGlowing} class="absolute glow" />
  <img src={Frame} alt="frame" />

</div>
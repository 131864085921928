/*	This work is licensed under Creative Commons GNU LGPL License.

	License: http://creativecommons.org/licenses/LGPL/2.1/
   Version: 0.9
	Author:  Stefan Goessner/2006
	Web:     http://goessner.net/ 
*/
export function json2xml(o, tab) {
	var toXml = function (v, name, ind) {
			var xml = '';
			if (v instanceof Array) {
				for (var i = 0, n = v.length; i < n; i++)
					xml += ind + toXml(v[i], name, ind + '\t') + '\n';
			} else if (typeof v == 'object') {
				var hasChild = false;
				xml += ind + '<' + name;
				for (var m in v) {
					if (m.charAt(0) == '@')
						xml += ' ' + m.substr(1) + '="' + v[m].toString() + '"';
					else hasChild = true;
				}
				xml += hasChild ? '>' : '/>';
				if (hasChild) {
					for (var q in v) {
						if (q == '#text') xml += v[q];
						else if (q == '#cdata') xml += '<![CDATA[' + v[q] + ']]>';
						else if (q.charAt(0) != '@') xml += toXml(v[q], q, ind + '\t');
					}
					xml +=
						(xml.charAt(xml.length - 1) == '\n' ? ind : '') + '</' + name + '>';
				}
			} else {
				xml += ind + '<' + name + '>' + v.toString() + '</' + name + '>';
			}
			return xml;
		},
		xml = '';
	for (var m in o) xml += toXml(o[m], m, '');
	return tab ? xml.replace(/\t/g, tab) : xml.replace(/\t|\n/g, '');
}

<script>
  const tempsItems = [
    {numerator: 2, denominator: 4, value: 128},
    {numerator: 3, denominator: 4, value: 192},
    {numerator: 4, denominator: 4, value: 256},
    {numerator: 3, denominator: 8, value: 96},
    {numerator: 6, denominator: 8, value: 192},
    {numerator: 9, denominator: 8, value: 288},
    {numerator: 12, denominator: 8, value: 394}
  ];

  function updateSelectedTempo(item) {
    selectedTempo = item;
  }
  export let selectedTempo;
</script>

<div>
  <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-3">
    {#each tempsItems as tempsItem}
      <div on:click={() => updateSelectedTempo(tempsItem)} class="{selectedTempo && selectedTempo === tempsItem ? 'border-blue-500 border-4' : 'border-white'} bg-white shadow rounded-md p-4 flex items-center justify-center cursor-pointer transform transition-transform hover:scale-105">
        <span>{tempsItem.numerator}/{tempsItem.denominator}</span>
      </div>
    {/each}
  </div>
</div>


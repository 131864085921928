import { getCurrentUser } from '../lib/client/firebase';
import { loading } from '../stores/Loading';

async function isAuthenticated() {
  loading.set(true); // Set loading to true
  try {
    const user = await getCurrentUser();
    return !!user; // Return true if user is authenticated
  } catch (error) {
    console.error('Authentication check failed:', error);
    return false; // Return false if an error occurs
  } finally {
    loading.set(false); // Set loading to false
  }
}

export async function authGuard() {
    const authenticated = await isAuthenticated();
    if (!authenticated) {
      window.location.href = '/#/welcome';
    }
    return authenticated;
  }
  
<script>
  export let imagePath;
  export let fillColor = 'white'; // Default color is white
</script>

<div class="flex items-center">
  <img src={imagePath} alt="SVG Image" class="w-6 h-6 filter-invert" style="fill: {fillColor}" />
</div>

<style>
    .w-6 {
        width: 1.5rem;
    }

    .h-6 {
        height: 1.5rem;
    }

    .filter-invert {
        filter: invert(1);
    }
</style>

<script lang="ts">
    import arrowLeft from "../assets/icons/arrow-left.svg";
    import SVGImage from "../components/SVGImage.svelte";
    import { replace } from "svelte-spa-router";
    import TempoItems from "../components/TempoItems.svelte";
    import { timeSignatureStore, realTimeRunningStore } from "../store";
    import { TimeSignature } from "../model/TimeSignature";
    import { notifications } from "../toast/Notifications";
    import Toast from "../toast/Toast.svelte";
    import {
        startFrequencyRecognizerRealTime
	    } from '../recognition/frequencyRecognizerRealTime.js';

    let selectedTempo

    function goBack() {
        replace('/record');
    }

    function goToEditor() {
        if (selectedTempo === null) {
            notifications.red('Please, select a time signature', 1500);
            return;
        }
        const timeSignature = new TimeSignature(selectedTempo.numerator, selectedTempo.denominator, selectedTempo.value)
        timeSignatureStore.set(timeSignature);
        replace('/editor');
        //realTimeRunningStore.update(() => {
		//	  return true;
		//  });

      //startFrequencyRecognizerRealTime();
    }


</script>

<div class="min-w-screen min-h-screen bg-gradient-1 relative flex flex-col">
    <Toast/>
    <div class="max-w-sm mx-auto w-full px-3 flex flex-1 flex-col py-5 gap-5">
        <!-- top section -->
        <div class="flex items-center justify-center relative">
            <!-- on:click={() => show = false} -->
            <button
                  on:click={goBack}
                    type="button"
                    class="outline-none cursor-pointer absolute left-0 -top-1.5 p-1"
            ><SVGImage imagePath={arrowLeft} class="text-white w-5" />
            </button>
            <div class="text-center space-y-4">
                <h1 class="text-xl font-semibold text-white-100 leading-none">
                    Score Settings
                </h1>
                <p
                        class="font-semibold leading-sung text-white-400 text-center "
                >
                    Please choose a title and subtitle.
                </p>
            </div>
        </div>
        <!-- middle section -->
        <div class="space-y-6 flex justify-center flex-1">
            <TempoItems bind:selectedTempo={selectedTempo}/>
        </div>
        <!-- Bottom section here -->

        <div class="relative ">
            <button
              on:click={goToEditor}
                    class=" bg-white-400 w-full p-4 rounded text-center text-lg leading-none font-semibold cursor-pointer text-dark-400 duration-300 ease-in-out hover:bg-white"
            >Accept Rhythm</button
            >
        </div>
    </div>
</div>

<script>
    import { closeModal } from "svelte-modals";
    import { fade } from "svelte/transition";
    import downloadAppGif from "../assets/icons/gifs/howToInstallApp.gif";
    import cross from "../assets/icons/cross.svg";
    import SVGImage from "./SVGImage.svelte";
    // provided by <Modals />
    export let isOpen;
    
  </script>
  
  {#if isOpen}
  <div
  role="dialog"
  class="fixed inset-0 flex justify-center items-center max-h-full overflow-auto pointer-events-none"
  transition:fade
  on:introstart
  on:outroend
>
  <div
    class="bg-dark-300 rounded-md w-96 max-w-full px-4 py-5 mx-auto relative z-10 pointer-events-auto"
  >
    <!-- Move the button inside this div -->
    <button
      on:click={closeModal}
      class="absolute top-2 right-2 flex justify-center items-center rounded-full w-8 h-8"
    >
      <SVGImage
        imagePath={cross}
        class="w-4 "
        size="16"
        fillColor="black"
      />
    </button>
    <div class="space-y-5 p-4">
      <div class="space-y-2 text-center">
        <h2 class="text-xl font-semibold text-white-100 leading-none">
          ¡Descarga la app para no perder el ritmo!
        </h2>
        <img src={downloadAppGif} alt="downloadAppGif" class="w-48 mx-auto" />
      </div>
    </div>
  </div>
</div>


  {/if}
  
  
  
<script>
    import { createEventDispatcher } from 'svelte';

    const dispatch = createEventDispatcher();
    export let product;

    function selectPlan() {
        console.log('click')
        
        dispatch('selectPlan');
	}
</script>



<div class="w-full max-w-sm p-4 bg-white border bg-zinc-700 rounded-lg shadow sm:p-8 dark:bg-zinc-700 dark:border-zinc-500">
    <h5 class="mb-4 text-xl font-medium text-gray-300 dark:text-gray-400">{product.name}</h5>
    <div class="mb-4 flex items-baseline text-gray-900 dark:text-white">
        <span class="text-5xl font-extrabold tracking-tight">{product.monthlyPrice}</span>
        <span class="text-3xl font-semibold">€</span>
        <span class="ms-1 text-xl font-normal text-gray-500 dark:text-gray-400">/mes</span>
    </div>
    <button type="button" on:click={() => selectPlan()} class="text-white bg-zinc-700 hover:bg-zinc-600 focus:ring-4 focus:outline-none focus:ring-blue-100 dark:bg-zinc-800 dark:hover:bg-zinc-800 dark:focus:ring-zinc-900 font-medium rounded-lg text-sm px-5 py-2.5 inline-flex justify-center w-full text-center">Escoger plan</button>
</div>

import { wrap } from 'svelte-spa-router/wrap';
import { authGuard } from './authMiddleware'; // Adjust the path as necessary

// Import your components
import Login from '../views/Login.svelte';
import Landing from '../views/Landing.svelte';
import Home from '../views/Home.svelte';
import Editor from '../views/Editor.svelte';
import Record from '../views/Record.svelte';
import TempoSelection from '../views/TempoSelection.svelte';
import Paywall from '../views/Paywall.svelte';
import CheckoutForm from '../views/CheckoutForm.svelte';
import DemoLevel from '../views/DemoLevel.svelte';

export const routes = {
  '/login': Login,
  '/welcome': Landing,
  '/': wrap({
    component: Home,
    conditions: [authGuard]
  }),
  '/home': wrap({
    component: Home,
    conditions: [authGuard]
  }),
  '/editor': wrap({
    component: Editor,
    conditions: [authGuard]
  }),
  '/record': wrap({
    component: Record,
    conditions: [authGuard]
  }),
  '/tempo-selection': wrap({
    component: TempoSelection,
    conditions: [authGuard]
  }),
  '/paywall': wrap({
    component: Paywall,
    conditions: [authGuard]
  }),
  '/checkout': wrap({
    component: CheckoutForm,
    conditions: [authGuard]
  }),
  '/demo': DemoLevel,
  '*': wrap({
    component: Home,
    conditions: [authGuard]
  })
};

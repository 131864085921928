<script>
    import SVGImage from "../components/SVGImage.svelte";
    import arrowLeft from "../assets/icons/arrow-left.svg";
    import grid from "../assets/icons/grid.svg";
    import logout from "../assets/icons/logout.svg";
    import { fly } from 'svelte/transition';
    import { auth, getCurrentUser } from "../lib/client/firebase";
    import { replace } from "svelte-spa-router";


    $: name = "";
    name = "";

    $: photoUrl = "";
    photoUrl = "";

    setUserData();

    async function signOut() {
        try {
            await auth.signOut();
            replace('/login');
        } catch (error) {
            console.log(error);
        }
    }

    export let show = true;
    const menuItems = [
        {
            heading: 'Cerrar sesión',
            icon: logout,
            action: () => signOut()
        },

    ];

    async function setUserData() {
        const user = await getCurrentUser();
        if (user != null) {
            name = user.displayName;
            photoUrl = user.photoURL;
        }
    }


</script>

<!-- max-w-sm mx-auto px-3 py-4 -->
{#if show}
    <div class="fixed inset-0 z-50" transition:fly={{x: -100, opacity: 1}} >
        <div class="w-60 mr-auto  px-3 flex flex-1 flex-col py-5 gap-5"  >
            <div class=" flex items-center justify-between">
                <button type="button" on:click={() => show = false} class="outline-none cursor-pointer -top-1.5 p-1 relative"
                >
                    <SVGImage imagePath="{arrowLeft}"  stroke="transparent" />
                </button>
                <div class="flex-col cursor-pointer">
                    <h1 class="text-xl font-medium text-white leading-none">Norelearn</h1>
                </div>
            </div>
            <div class="space-y-10">
                <div class="bg-white-100/10 px-3 py-1.5 flex justify-between">
                    <div class="flex gap-3 items-center">
                        <div
                                class="relative w-14 h-14 overflow-hidden flex justify-center items-center rounded-full shrink-0"
                        >
                            <img
                                    src={ photoUrl }
                                    alt="user"
                                    class="object-cover aspect-square"
                            />
                        </div>
                        <div class="space-y-2">
                            <h3
                                    class="font-semibold text-white-100 leading-none tracking-wide"
                            >
                                { name }
                            </h3>
                        </div>
                    </div>
                </div>
                <div class="space-y-3">
                    {#each menuItems as menuItem}
                        <button
                          class="flex gap-3 items-center px-3 py-2 hover:bg-white-400 text-white-400 hover:text-dark-400 ease-in-out duration-300"
                          on:click={() => menuItem.action()}
                        >

                            <SVGImage imagePath="{menuItem.icon}" />
                            <span>{menuItem.heading}</span>
                        </button>
                    {/each}
                </div>
            </div>
        </div>
    </div>
{/if}


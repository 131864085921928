<script>
	import { selectedPlan } from "stores/Paywall";
	import { get } from "svelte/store";
    import { onMount } from 'svelte';
    import { replace } from 'svelte-spa-router';
    import { submitPurchaseData } from '../lib/client/api/purchase';
	import LoadingSpinner from "../routes/LoadingSpinner.svelte";
    import BackArrow from "../components/BackArrow.svelte";

    let email = "";
    let cardNumber = "";
    let cardExpiration = "";
    let cardName = "";
    let cvc = "";

    let product = null;

    let emailError = "";
    let cardNumberError = "";
    let cardNameError = "";
    let cardExpirationError = "";
    let cvcError = "";
    
    let processing = false;
    let displayError = false;

    onMount(() => {
        product = get(selectedPlan);
        if (product === null) {
            replace('/paywall');
        }
    });

    function handleSubmit() {
        if (!isValidForm()) {
            console.log("Invalid form");
            return;
        }
        processing = true;
        submitPurchaseData(product, email);
        processing = false;
        displayError = true;
        
    }

    function isValidForm() {
            // Data validation
            if (!email || !cardNumber || !cardExpiration || !cardName || !cvc) {
                console.log("Please fill in all fields");
                return false;
            }

            // Email validation
            if (!validateEmail(email)) {
                emailError = "Please enter a valid email";
                return false;
            } else {
                emailError = "";
            }

            // Card validation
            if (!validateCard(cardNumber)) {
                cardNumberError = "Please enter a valid card number";
                return false;
            } else {
                cardNumberError = "";
            }

            // Card owner name validation
            if (!validateCardOwnerName(cardName)) {
                cardNameError = "Please enter a valid card owner name";
                return false;
            } else {
                cardNameError = "";
            }

            // Card expiration validation
            if (!validateCardExpiration(cardExpiration)) {
                cardExpirationError = "Please enter a valid card expiration";
                return false;
            } else {
                cardExpirationError = "";
            }

            // CVC validation
            if (!validateCVC(cvc)) {
                cvcError = "Please enter a valid CVC";
                return false;
            } else {
                cvcError = "";
            }

            return true;
        }

    function validateEmail(email) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }

    function validateCard(cardNumber) {
        const cardRegex = /^[0-9]{4}-[0-9]{4}-[0-9]{4}-[0-9]{4}$/;
        return cardRegex.test(cardNumber);
    }

    function validateCardOwnerName(cardName) {
        const cardNameRegex = /^[A-Za-z\s]+$/;
        return cardNameRegex.test(cardName);
    }

    function validateCardExpiration(cardExpiration) {
        const cardExpirationRegex = /^(0[1-9]|1[0-2])\/?([0-9]{2})$/;
        return cardExpirationRegex.test(cardExpiration);
    }

    function validateCVC(cvc) {
        const cvcRegex = /^[0-9]{3}$/;
        return cvcRegex.test(cvc);
    }
</script>
<main>
    <div class="min-w-screen min-h-screen bg-gradient-1 relative flex flex-col justify-center items-center">
        <div class="absolute top-4 left-4 p-4">
            <BackArrow path='/paywall' />
        </div>
        {#if processing}
            <LoadingSpinner/>
        {:else if displayError}
            <div class="bg-white p-8 rounded-md mt-4 mb-4 mx-4">
                <h2 class="text-2xl font-semibold mb-4">Error</h2>
                <p class="text-red-500">Ha ocurrido un eror al procesar la compra.</p>
            </div>
        {:else}
            <form on:submit|preventDefault={handleSubmit}>   
                <div class="mt-10 px-4 pt-8 lg:mt-0 mx-4 p-4 bg-white border bg-zinc-700 rounded-md shadow sm:p-8 dark:bg-zinc-700 dark:border-zinc-500">
                    <div class="flex items-center mb-4">
                        <p class="text-xl font-medium text-gray-900 dark:text-white">Detalles de pago</p>
                    </div>
                    <p class="text-gray-400">Completa tu pedido proporcionando los detalles de pago.</p>
                    <div class="">
                        <label for="email" class="mt-4 mb-2 block text-sm font-medium text-gray-900 dark:text-white">Email</label>
                        <div class="relative">
                        <input bind:value={email} required type="text" id="email" name="email" class="w-full rounded-md border border-gray-200 px-4 py-3 pl-11 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500" placeholder="example@example.com"/>
                        <div class="pointer-events-none absolute inset-y-0 left-0 inline-flex items-center px-3">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207" />
                            </svg>
                        </div>
                        {#if emailError}
                            <p class="text-red-500 text-sm mt-1">{emailError}</p>
                        {/if}
                        </div>
                        <label for="card-holder" class="mt-4 mb-2 block text-sm font-medium text-gray-900 dark:text-white">Titular de la tarjeta</label>
                        <div class="relative">
                        <input bind:value={cardName} required type="text" id="card-holder" name="card-holder" class="w-full rounded-md border border-gray-200 px-4 py-3 pl-11 text-sm uppercase shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500" placeholder="Nombre Apellido1 Apellido2" />
                        <div class="pointer-events-none absolute inset-y-0 left-0 inline-flex items-center px-3">
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5zm6-10.125a1.875 1.875 0 11-3.75 0 1.875 1.875 0 013.75 0zm1.294 6.336a6.721 6.721 0 01-3.17.789 6.721 6.721 0 01-3.168-.789 3.376 3.376 0 016.338 0z" />
                            </svg>
                        </div>
                        {#if cardNameError}
                            <p class="text-red-500 text-sm mt-1">{cardNameError}</p>
                        {/if}
                        </div>
                        <label for="card-no" class="mt-4 mb-2 block text-sm font-medium text-gray-900 dark:text-white">Detalles de la tarjeta</label>
                        <div class="flex">
                        <div class="relative w-7/12 flex-shrink-0">
                            <input bind:value={cardNumber} required type="text" id="card-no" name="card-no" class="w-full rounded-md border border-gray-200 px-2 py-3 pl-11 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500" placeholder="xxxx-xxxx-xxxx-xxxx"/>
                            <div class="pointer-events-none absolute inset-y-0 left-0 inline-flex items-center px-3">
                            <svg class="h-4 w-4 text-gray-400" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                                <path d="M11 5.5a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-2a.5.5 0 0 1-.5-.5v-1z" />
                                <path d="M2 2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2H2zm13 2v5H1V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1zm-1 9H2a1 1 0 0 1-1-1v-1h14v1a1 1 0 0 1-1 1z" />
                            </svg>
                            </div>
                        </div>
                        <input bind:value={cardExpiration} required type="text" name="credit-expiry" class="w-full rounded-md border border-gray-200 px-2 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500" placeholder="MM/YY"/>
                        <input bind:value={cvc} required type="text" name="credit-cvc" class="w-1/6 flex-shrink-0 rounded-md border border-gray-200 px-2 py-3 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500" placeholder="CVC" />
                        </div>
                        {#if cardNumberError}
                            <p class="text-red-500 text-sm mt-1">{cardNumberError}</p>
                        {/if}
                        {#if cardExpirationError}
                            <p class="text-red-500 text-sm mt-1">{cardExpirationError}</p>
                        {/if}
                        {#if cvcError}
                            <p class="text-red-500 text-sm mt-1">{cvcError}</p>
                        {/if}
                
                        <!-- Total -->
                        <!--  Pago total:66€    Obtendrás 6 meses de subscripción -->
                        <div class="mt-6 flex items-center justify-between text-gray-900 dark:text-white">
                            <p class="text-sm font-medium">Total</p>
                            <p class="text-2xl font-semibold">{product?.price ?? "--"}€</p>
                        </div>
                        <div class="flex items-center justify-between">
                            <p></p>
                            <p class="text-gray-300">Obtendrás {product?.duration ?? "--"} de suscripción</p>
                        </div>
                    </div>
                    
                    <button class="my-5 text-white bg-zinc-700 hover:bg-zinc-600 focus:ring-4 focus:outline-none focus:ring-blue-100 dark:bg-zinc-800 dark:hover:bg-zinc-800 dark:focus:ring-zinc-900 font-medium rounded-lg text-sm px-5 py-2.5 inline-flex justify-center w-full text-center">Iniciar suscripción</button>
                </div>
            </form>
        {/if}
    </div>
</main>

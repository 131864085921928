import confetti from 'canvas-confetti';

const DURATION = 12 * 1000;

function throwConfetti() {
    var animationEnd = Date.now() + DURATION;
    var scalar = 1.2;
    var emoji = confetti.shapeFromText({ text: '🎵', scalar });


    var defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0, scalar: scalar};

    
    function randomInRange(min, max) {
      return Math.random() * (max - min) + min;
    }
    
    var interval = setInterval(function() {
      var timeLeft = animationEnd - Date.now();
    
      if (timeLeft <= 0) {
        return clearInterval(interval);
      }
    
      var particleCount = 50 * (timeLeft / DURATION);
      // since particles fall down, start a bit higher than random
      confetti({ ...defaults, particleCount, origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 }, shapes: ['circle', emoji] });
      confetti({ ...defaults, particleCount, origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 }, shapes: ['circle', emoji] });
    }, 250);
}

export default throwConfetti;
<script>
    import { onMount } from 'svelte';
    import { Products } from '../model/Products';
    import ProductCard from '../components/ProductCard.svelte';
    import BackArrow from '../components/BackArrow.svelte';
    import { selectedPlan } from '../stores/Paywall';
    import { replace } from 'svelte-spa-router';

    let products = [];

    onMount(async () => {
        products = Products.getAll();
    });

    function selectPlan(product) {    
        selectedPlan.set(product);
		replace('/checkout');
    }

</script>
<div class="min-w-screen min-h-screen bg-gradient-1 relative flex flex-col justify-center items-center">
    <div class="absolute top-4 left-4 p-4">
        <BackArrow path='/' />
    </div>
    <h3 class="text-3xl font-bold text-center text-white p-8">¡Enhorabuena por completar todas las lecciones!</h3>
    <p class="text-center text-white mx-4 max-w-prose">Has demostrado un compromiso impresionante con tu aprendizaje. Ahora, como recompensa, ¡puedes suscribirte para acceder a la versión completa de nuestra aplicación! Disfruta de todas las características y contenido adicional que tenemos para ofrecerte. Estamos emocionados de acompañarte en tu viaje de aprendizaje musical.</p>
    
    <div class="grid grid-cols-1 gap-4 px-4 py-8 sm:grid-cols-2 lg:grid-cols-3">
        {#each products as product}
            <ProductCard product={product} class="m-4" on:selectPlan={() => selectPlan(product)}/>
        {/each}
    </div>
</div>

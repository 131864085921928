<script>
    import playButton from "../assets/icons/playButton.svg";
	import send6 from "../assets/icons/send6.svg";
	import Vex from 'vexflow';

    import { onMount, onDestroy } from 'svelte';
	import { replace } from 'svelte-spa-router';
	import {
		contextStore,
		currentMusicSheetStore,
		frequencyEnabledStore,
		currentlyListeningStore,
		saveSheetStore
	} from "../store";
	import { scrollCountStore } from '../stores/ScrollStore';
	import { get } from 'svelte/store';
	import {
		startSpeechRecognizer,
		stopSpeechRecognizer,
	} from '../recognition/speechRecognizer.js';
	import { MEASURE_WIDTH } from "utils/contextConstants";

	//JSON TO XML
	import { json2xml } from '../json2xml.js';
	import { playAll } from '../audio/playback.js';
	import {
		speechCommandModel,
		lstmNorewingModel,
		uploadSheet,
		storeHumanSheetLSTM,
		uploadSheetToVAEDataset
	} from "../Editor.ts";
	import SVGImage from "../components/SVGImage.svelte";
	import {notifications} from '../toast/Notifications'
	import {
		updateCurrentFigure
	} from "../modules/storeManager.js";
	import { initSheet } from "../modules/sheetManager";
	import { getDemoLevel } from 'lib/client/api/musicSheets';

    
    $: active = $saveSheetStore;
    let level;
    
    onMount(() => {
		const portrait = window.matchMedia("(orientation: portrait)").matches;
		if (portrait) {
			notifications.grey('Por favor, gira tu dispositivo para una mejor experiencia.', 3000);
		}
        let context = get(contextStore);
        const VF = Vex.Flow;
		const div = document.getElementById('vf');
		const renderer = new VF.Renderer(div, VF.Renderer.Backends.SVG);
		context = renderer.getContext();

		context.setFont('Arial', 10, '').setBackgroundFillStyle('#eed');

		contextStore.update(() => {
			return context;
		});
		updateCurrentFigure('Negra');
        getDemoLevel().then((response) => {
            level = response;
            currentMusicSheetStore.set(level);

            console.log({level});
            initSheet()
        });
		listenToScrollNeed();
        return () => {};
    });

    onDestroy(() => {
		stopRecognitionSpeech();
	});

	function listenToScrollNeed() {
		scrollCountStore.subscribe((value) => {
			if (value > 0) {
				console.log("scrolling");
				scroll(value);
			}
		});
	}

	function scroll(value){
		const sheetContainer = document.getElementById("scrollable-container");
		sheetContainer.scrollTo({
			left: value * (MEASURE_WIDTH / 4),
			behavior: 'smooth'
		});
	}

    const speech = speechCommandModel();
	//const lstm = lstmNorewingModel();
	if (speech != null) {
		notifications.green('¡Estamos listos para escucharte! Inicia cuando desees.', 1500);
	} else {
		notifications.red('¡Oops! Parece que hay un problema técnico. Por favor, inténtalo de nuevo más tarde o ponte en contacto con soporte.', 1500);
	}
	//RECORDER START////////////////////////////////////////////////
	//TODO: Pasar esto a un nuevo .js en el que esten todas las funcionalidades como escuchar microfono, hablar al modelo de IA... (que no sea Editor.ts)
	
	let constraintObj = {
		audio: true,
		video: false,
	};

	//handle older browsers that might implement getUserMedia in some way
	if (navigator.mediaDevices === undefined) {
		navigator.mediaDevices = {};
		navigator.mediaDevices.getUserMedia = function (constraintObj) {
			let getUserMedia =
				navigator.getUserMedia ||
				navigator.webkitGetUserMedia ||
				navigator.mozGetUserMedia ||
				navigator.msGetUserMedia;
			if (!getUserMedia) {
				return Promise.reject(
					new Error('getUserMedia is not implemented in this browser')
				);
			}
			return new Promise(function (resolve, reject) {
				getUserMedia.call(navigator, constraintObj, resolve, reject);
			});
		};
	} else {
		navigator.mediaDevices
			.enumerateDevices()
			.then((devices) => {
				devices.forEach((device) => {
					console.log(device.kind.toUpperCase(), device.label);
					//, device.deviceId
				});
			})
			.catch((err) => {
				console.log(err.name, err.message);
			});
	}

	navigator.mediaDevices
		.getUserMedia(constraintObj)
		.then(function (mediaStreamObj) {
			//connect the media stream to the first video element
			let audio = document.querySelector('audio');
			if ('srcObject' in audio) {
				audio.srcObject = mediaStreamObj;
			} else {
				//old version
				audio.src = window.URL.createObjectURL(mediaStreamObj);
			}

			/*audio.onloadedmetadata = function(ev) {
                //show in the video element what is being captured by the webcam
                audio.play();
            };*/

			//add listeners for saving video/audio
			let start = document.getElementById('btnStart');
			let stop = document.getElementById('btnStop');
			let options = {
				audioBitsPerSecond: 48000,
				//mimeType: 'audio/wav'
				//mimeType : 'audio/ogg'
			};
			let mediaRecorder = new MediaRecorder(mediaStreamObj, options);
			let chunks = [];

			start.addEventListener('click', () => {
				startSpeechRecognizer();
			});

			stop.addEventListener('click', () => {
				stopSpeechRecognizer();
			});
			mediaRecorder.ondataavailable = function (ev) {
				chunks.push(ev.data);
			};
			mediaRecorder.onstop = () => {};
		})
		.catch(function (err) {
			console.log(err.name, err.message);
		});

	var myObj = {
		'score-partwise': { '@version': '4.0', 'part-list': null },
	};
	let resultado = json2xml(myObj, '');
	console.log(resultado);
	let color = 'hidden';

	export function open() {
		active = true;
	}
	export function close() {
		active = false;
	}

	$: title = '';
	$: subtitle = '';

	export function closeSaving() {
		console.log(title);
		uploadSheet(title, subtitle);
		active = false;
	}

	//XML TO JSON
	/*import {parseXml} from './parseXml.js';
    var xml = '<?xml version="1.0" encoding="UTF-8" standalone="no"?><!DOCTYPE score-partwise PUBLIC "-//Recordare//DTD MusicXML 4.0 Partwise//EN" "http://www.musicxml.org/dtds/partwise.dtd"><score-partwise version="4.0"><part-list></part-list></score-partwise>';
    var dom = parseXml(xml);
    let resultado=xml2json(dom,"");
    console.log(resultado);*/


	const playAllMusic = () => {
		console.log("Playing...")
		playAll();
		console.log("Finished playing :)")
	};

	const showAndQuantize = () => {
		color = 'visible';
	};

	

	function startLearningRecognition(){
		//it checks whether it is actually listening or it must be called to the function
		const currentlyListening = get(currentlyListeningStore);

		if (currentlyListening == false) {
			const frequencyEnabled = get(frequencyEnabledStore);
			if (frequencyEnabled == true) {
				frequencyStart();
			} else {
				startSpeechRecognizer();
				console.log("entré speech recognizer")
				let hiddenButtonSpeech = document.getElementById('hiddenButtonSpeech');
				hiddenButtonSpeech.style.display = 'block';
				//let recordingButton = document.getElementById('recordingButton');
				//recordingButton.style.display = 'none';
			}

			currentlyListeningStore.update(() => {
				return true;
			});
		}
	}

	function stopLearningRecognition(){
		console.log("stopped recognition")
		let recordingButton = document.getElementById('recordingButton');
		recordingButton.style.display = 'block';
		let hiddenButtonSpeech = document.getElementById('hiddenButtonSpeech');
		hiddenButtonSpeech.style.display = 'none';
		currentlyListeningStore.update(() => {
				return false;
			});
	}

		//AI OVERLAY
	//const openNav  = () => {
	//const overlay = document.getElementById('overlay');
	//overlay.style.display = 'block';
	//}
	function uploadCorrenctionNoreWand(){
		hiddenButton3.style.display = 'none';
		storeHumanSheetLSTM() 
		uploadSheetToVAEDataset()
	}

	const stopRecognitionSpeech = () => {
		stopSpeechRecognizer();
		let hiddenButtonSpeech = document.getElementById('hiddenButtonSpeech');
		hiddenButtonSpeech.style.display = 'none';
		currentlyListeningStore.update(() => {
				return false;
			});
	}

</script>

<div class="min-w-screen min-h-screen bg-gradient-1 relative flex flex-col">
	
	<div
		class="max-w-4xl mx-auto w-full px-3 flex flex-1 items-center justify-center py-5 gap-5"
	>
		<!-- Left side -->
		<div class="flex justify-center items-stretch space-x-6 w-full">
			<div class="bg-white rounded-md flex-1 p-4 sm:max-w-md md:max-w-xl xl:max-w-3xl 2xl:max-w-5xl">
				<div class="sheetDisplayer">
					<div id="scrollable-container" class="overflow-auto">
						<div class="flex justify-center items-center">
							<div class="w-full max-w-screen-md">
								<div class="bg-white p-4 shadow-md rounded-md">
									<div id="vf" class="vf-container"></div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="inset-x-0  bottom-0 p-4">
					<div class="flex justify-between ml-auto">
						<!--<button on:click={playAllMusic}
										class="outline-none bg-dark-600 rounded-full text-white flex justify-center items-center w-12 h-12 -translate-x-1/2 shrink-0"
						>
							<SVGImage imagePath={playButton} fillColor="white" />
						</button>-->
						
						<button on:click={startLearningRecognition} id="recordingButton" style="display: flex; justify-content: center; align-items: center;" class="w-10 h-10 rounded-full bg-red">
							<span id="recordingDot" class="w-3 h-3" style="background-color: white; border-radius: 50%;"></span>
						</button>

						<button on:click={stopLearningRecognition} id="hiddenButton" style="display: none;" type="button" class="w-10 h-10 rounded-full bg-red flex justify-center items-center">
							<div class="relative">
								<span id="hiddenButton2" class="w-3 h-3 bg-white absolute inset-0 m-auto"></span>
							</div>
						</button>

						<button on:click={uploadCorrenctionNoreWand} style="display: none;" id="hiddenButton3" class="w-10 h-10 rounded-full bg-green-500 flex justify-center items-center">
							<div style="margin-left: 7px;">
							<SVGImage imagePath={send6} id="hiddenButton3" fillColor="transparent" style="height: 80%; width: 80%; display: none;"/>
						</div>
						</button>

						

						<button on:click={stopRecognitionSpeech} id="hiddenButtonSpeech" style="display: none;" type="button" class="w-10 h-10 rounded-full bg-red flex justify-center items-center">
							<div class="relative">
								<span id="hiddenButtonSpeech" class="w-3 h-3 bg-white absolute inset-0 m-auto"></span>
							</div>
						</button>
						
						<div id="overlay"></div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<style>
	 #overlay {
      position: fixed;
      display: none;
	  justify-content: center;
   	  align-items: center;	
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 200000;
      background: rgba(0, 0, 0, 0.78);
    }

	
	.sheetDisplayer {
		grid-column: 3 / 18;
		grid-row: 1;
	}
</style>

import * as Meyda from 'meyda';
import * as load from 'audio-loader';
import * as wav from 'node-wav';
import KNN from 'ml-knn';
import { doremi } from '../store';
import { get } from 'svelte/store';
import * as tf from '@tensorflow/tfjs';
import { PitchDetector } from 'pitchy';

import { notifications } from '../toast/Notifications.ts';
import { addNoteWithOctave } from "../modules/editNoteManager.js";
/* THERE ARE THREE DIFFERENT MODES:

1-DICTATION MODE: YOU HAVE TO STOP BETWEEN DICTATIONS/HUMMINGS LO LET THE PROGRAM THINK TO
2-SPEED DICTATION MODE: YOU CAN DICTATE/HUM THINGS IN ANY WANTED VELOCITY
3-REAL TIME MODE: GIVEN A TEMPO, CATCHES EVEN RITHM 

*/
console.time('dbsave');
console.timeLog('dbsave');
console.timeEnd('dbsave');

var FEATURE_NAME_RMS = 'rms';
var THRESHOLD_RMS_FREQUENCY = 0.05;
var cur_rms = 0;
var frequencyStacked = [];
let CLARITY_THRESHOLD = 90;
let currentFrequency;
let currentClarity;
let analyzer;
let audioContext;

export function startFrequencyRecognizer() {
	notifications.grey('Starting Frequency Recognition...', 1500);
	audioContext = new window.AudioContext();
	const analyserNode = audioContext.createAnalyser();
	audioContext.resume();

	//TODO: METER ESTO EN FUNCION DE SHOWFREQUENCY O EL CONTENIDO ()
	navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
		audioContext.createMediaStreamSource(stream).connect(analyserNode);
		const detector = PitchDetector.forFloat32Array(analyserNode.fftSize);
		const input = new Float32Array(detector.inputLength);
		PitchAndRMSgetter(analyserNode, detector, input, audioContext.sampleRate);
	});

	// create meyda analyzer
	// and connect to mic source
	onMicDataCall([FEATURE_NAME_RMS], dictateFrequency)
		.then((meydaAnalyzer) => {
			meydaAnalyzer.start();
		})
		.catch((err) => {
			alert(err);
		});
}

export function stopFrequencyRecognizer() {
	notifications.grey('Stopped Frequency Recognition...', 1500);
	analyzer.stop();
	audioContext.close();
}

export function setSensitivity(number) {
	THRESHOLD_RMS = number;
}

const statisticMode = (a) =>
	Object.values(
		a.reduce((count, e) => {
			if (!(e in count)) {
				count[e] = [0, e];
			}

			count[e][0]++;
			return count;
		}, {})
	).reduce((a, v) => (v[0] < a[0] ? a : v), [0, null])[1];

//Fastmode
function dictateFrequency(features) {
	cur_rms = features[FEATURE_NAME_RMS];

	if (cur_rms > THRESHOLD_RMS_FREQUENCY) {
		//console.log(currentFrequency);
		//frequencyStacked=frequencyStacked.concat(currentFrequency)
		if (currentClarity > CLARITY_THRESHOLD) {
			console.log('Clear Frequencies: ' + currentFrequency);
			frequencyStacked = frequencyStacked.concat(
				frequencyParserToRealFreq(currentFrequency)
			);
		}
	}

	if (cur_rms < THRESHOLD_RMS_FREQUENCY && frequencyStacked.length != 0) {
		let mode = statisticMode(frequencyStacked);
		console.log('result: ');
		console.log(mode);

		let result = frequencyParserHumanVoice(mode);

		console.log(result[0]);
		console.log(result[1]);
		console.log(result[2]);
		//This is kNN
		//var prediction = doremiModel.predict(mfccStacked)
		//This is CNN
		// modifyOctave(result[2])
		console.log('add note: ' + result[0]);
		// TODO (What is result[1]?)
		const noteIndex = undefined;
		console.log('noteIndex' + noteIndex);
		if (result[0] != undefined) {
			if (result[1] !== null) {
				addNoteWithOctave(result[0] + result[1], result[2]); //+1
			} else {
				addNoteWithOctave(result[0], result[2]); //+1
			}
		}
		frequencyStacked = [];
	}
}

function /* get new audio 
context object */
createAudioCtx() {
	let AudioContext = window.AudioContext || window.webkitAudioContext;
	return new AudioContext({
		sampleRate: 16000,
	});
}

function /* create microphone
audio input source from 
audio context */
createMicSrcFrom(audioCtx) {
	/* get microphone access */
	return new Promise((resolve, reject) => {
		/* only audio */
		let constraints = {
			audio: {
				sampleRate: 16000,
				channelCount: 1,
				noiseSuppression: true,
			},
			video: false,
		};
		//noiseSuppression: true,
		navigator.mediaDevices
			.getUserMedia(constraints)
			.then((stream) => {
				/* create source from
            microphone input stream */

				let src = audioCtx.createMediaStreamSource(stream);
				resolve(src);
			})
			.catch((err) => {
				reject(err);
			});
	});
}

function /* call given function
on new microphone analyser
data */
onMicDataCall(features, callback) {
	return new Promise((resolve, reject) => {
		let audioCtx = createAudioCtx();
		createMicSrcFrom(audioCtx)
			.then((src) => {
				analyzer = Meyda.createMeydaAnalyzer({
					audioContext: audioCtx,
					source: src,
					bufferSize: 512,
					sampleRate: 16000,
					windowingFunction: 'hanning',
					numberOfMFCCCoefficients: 13,
					inputs: 1,
					featureExtractors: features,
					callback: callback,
				});
				resolve(analyzer);
			})
			.catch((err) => {
				reject(err);
			});
	});
}

function PitchAndRMSgetter(analyserNode, detector, input, sampleRate) {
	analyserNode.getFloatTimeDomainData(input);
	const [pitch, clarity] = detector.findPitch(input, sampleRate);
	let freq = Math.round(pitch * 10) / 10;
	//console.log(freq);
	let roundClarity = Math.round(clarity * 100);
	currentFrequency = freq;
	currentClarity = roundClarity;
	window.setTimeout(
		() => PitchAndRMSgetter(analyserNode, detector, input, sampleRate),
		10
	);
	//PitchAndRMSgetter(analyserNode, detector, input, sampleRate);
}

function frequencyParser(freq) {
	let note;
	let octave;
	let symbol;
	if (freq < 16.835) {
		note = 'c';
		octave = 0;
	} else if (freq > 16.835 && freq < 17.835) {
		note = 'db';
		octave = 0;
	} else if (freq > 17.835 && freq < 18.9) {
		note = 'd';
		octave = 0;
	} else if (freq > 18.9 && freq < 20.025) {
		note = 'eb';
		octave = 0;
	} else if (freq > 20.025 && freq < 21.215) {
		note = 'e';
		octave = 0;
	} else if (freq > 21.215 && freq < 22.475) {
		note = 'f';
		octave = 0;
	} else if (freq > 22.475 && freq < 23.81) {
		note = 'gb';
		octave = 0;
	} else if (freq > 23.81 && freq < 25.23) {
		note = 'g';
		octave = 0;
	} else if (freq > 25.23 && freq < 26.73) {
		note = 'ab';
		octave = 0;
	} else if (freq > 26.73 && freq < 28.32) {
		note = 'a';
		octave = 0;
	} else if (freq > 28.32 && freq < 30.005) {
		note = 'bb';
		octave = 0;
	} else if (freq > 30.005 && freq < 31.785) {
		note = 'b';
		octave = 0;
	} else if (freq > 31.785 && freq < 33.675) {
		note = 'c';
		octave = 1;
	} else if (freq > 33.675 && freq < 35.68) {
		note = 'db';
		octave = 1;
	} else if (freq > 35.68 && freq < 37.8) {
		note = 'd';
		octave = 1;
	} else if (freq > 37.8 && freq < 40.045) {
		note = 'eb';
		octave = 1;
	} else if (freq > 40.045 && freq < 42.425) {
		note = 'e';
		octave = 1;
	} else if (freq > 42.425 && freq < 44.95) {
		note = 'f';
		octave = 1;
	} else if (freq > 44.95 && freq < 47.625) {
		note = 'gb';
		octave = 1;
	} else if (freq > 47.625 && freq < 50.455) {
		note = 'g';
		octave = 1;
	} else if (freq > 50.455 && freq < 53.455) {
		note = 'ab';
		octave = 1;
	} else if (freq > 53.455 && freq < 56.635) {
		note = 'a';
		octave = 1;
	} else if (freq > 56.635 && freq < 60.005) {
		note = 'bb';
		octave = 1;
	} else if (freq > 60.005 && freq < 63.575) {
		note = 'b';
		octave = 1;
	} else if (freq > 63.575 && freq < 67.355) {
		note = 'c';
		octave = 2;
	} else if (freq > 67.355 && freq < 71.36) {
		note = 'db';
		octave = 2;
	} else if (freq > 71.36 && freq < 75.6) {
		note = 'd';
		octave = 2;
	} else if (freq > 75.6 && freq < 80.095) {
		note = 'eb';
		octave = 2;
	} else if (freq > 80.095 && freq < 84.86) {
		note = 'e';
		octave = 2;
	} else if (freq > 84.86 && freq < 89.905) {
		note = 'f';
		octave = 2;
	} else if (freq > 89.905 && freq < 95.25) {
		note = 'gb';
		octave = 2;
	} else if (freq > 95.25 && freq < 100.915) {
		note = 'g';
		octave = 2;
	} else if (freq > 100.915 && freq < 106.915) {
		note = 'ab';
		octave = 2;
	} else if (freq > 106.915 && freq < 113.27) {
		note = 'a';
		octave = 2;
	} else if (freq > 113.27 && freq < 120.005) {
		note = 'bb';
		octave = 2;
	} else if (freq > 120.005 && freq < 127.14) {
		note = 'b';
		octave = 2;
	} else if (freq > 127.14 && freq < 134.7) {
		note = 'c';
		octave = 3;
	} else if (freq > 134.7 && freq < 142.71) {
		note = 'db';
		octave = 3;
	} else if (freq > 142.71 && freq < 151.195) {
		note = 'd';
		octave = 3;
	} else if (freq > 151.195 && freq < 160.185) {
		note = 'eb';
		octave = 3;
	} else if (freq > 160.185 && freq < 169.71) {
		note = 'e';
		octave = 3;
	} else if (freq > 169.71 && freq < 179.805) {
		note = 'f';
		octave = 3;
	} else if (freq > 179.805 && freq < 190.5) {
		note = 'gb';
		octave = 3;
	} else if (freq > 190.5 && freq < 201.825) {
		note = 'g';
		octave = 3;
	} else if (freq > 201.825 && freq < 213.825) {
		note = 'ab';
		octave = 3;
	} else if (freq > 213.825 && freq < 226.54) {
		note = 'a';
		octave = 3;
	} else if (freq > 226.54 && freq < 240.01) {
		note = 'bb';
		octave = 3;
	} else if (freq > 240.01 && freq < 254.285) {
		note = 'b';
		octave = 3;
	} else if (freq > 254.285 && freq < 269.405) {
		note = 'c';
		octave = 4;
	} else if (freq > 269.405 && freq < 285.42) {
		note = 'db';
		octave = 4;
	} else if (freq > 285.42 && freq < 302.395) {
		note = 'd';
		octave = 4;
	} else if (freq > 302.395 && freq < 320.38) {
		note = 'eb';
		octave = 4;
	} else if (freq > 320.38 && freq < 339.43) {
		note = 'e';
		octave = 4;
	} else if (freq > 339.43 && freq < 359.61) {
		note = 'f';
		octave = 4;
	} else if (freq > 359.61 && freq < 380.995) {
		note = 'gb';
		octave = 4;
	} else if (freq > 380.995 && freq < 403.65) {
		note = 'g';
		octave = 4;
	} else if (freq > 403.65 && freq < 427.65) {
		note = 'ab';
		octave = 4;
	} else if (freq > 427.65 && freq < 453.08) {
		note = 'a';
		octave = 4;
	} else if (freq > 453.08 && freq < 480.02) {
		note = 'bb';
		octave = 4;
	} else if (freq > 480.02 && freq < 508.565) {
		note = 'b';
		octave = 4;
	} else if (freq > 508.565 && freq < 538.81) {
		note = 'c';
		octave = 5;
	} else if (freq > 538.81 && freq < 570.85) {
		note = 'db';
		octave = 5;
	} else if (freq > 570.85 && freq < 604.79) {
		note = 'd';
		octave = 5;
	} else if (freq > 604.79 && freq < 640.75) {
		note = 'eb';
		octave = 5;
	} else if (freq > 640.75 && freq < 678.855) {
		note = 'e';
		octave = 5;
	} else if (freq > 678.855 && freq < 719.225) {
		note = 'f';
		octave = 5;
	} else if (freq > 719.225 && freq < 761.99) {
		note = 'gb';
		octave = 5;
	} else if (freq > 761.99 && freq < 807.3) {
		note = 'g';
		octave = 5;
	} else if (freq > 807.3 && freq < 855.305) {
		note = 'ab';
		octave = 5;
	} else if (freq > 855.305 && freq < 906.165) {
		note = 'a';
		octave = 5;
	} else if (freq > 906.165 && freq < 960.05) {
		note = 'bb';
		octave = 5;
	} else if (freq > 960.05 && freq < 1017.135) {
		note = 'b';
		octave = 5;
	} else if (freq > 1017.135 && freq < 1077.615) {
		note = 'c';
		octave = 6;
	} else if (freq > 1077.615 && freq < 1141.695) {
		note = 'db';
		octave = 6;
	} else if (freq > 1141.695 && freq < 1209.585) {
		note = 'd';
		octave = 6;
	} else if (freq > 1209.585 && freq < 1281.51) {
		note = 'eb';
		octave = 6;
	} else if (freq > 1281.51 && freq < 1357.71) {
		note = 'e';
		octave = 6;
	} else if (freq > 1357.71 && freq < 1438.445) {
		note = 'f';
		octave = 6;
	} else if (freq > 1438.445 && freq < 1523.98) {
		note = 'gb';
		octave = 6;
	} else if (freq > 1523.98 && freq < 1614.6) {
		note = 'g';
		octave = 6;
	} else if (freq > 1614.6 && freq < 1710.61) {
		note = 'ab';
		octave = 6;
	} else if (freq > 1710.61 && freq < 1812.33) {
		note = 'a';
		octave = 6;
	} else if (freq > 1812.33 && freq < 1920.095) {
		note = 'bb';
		octave = 6;
	} else if (freq > 1920.095 && freq < 2034.265) {
		note = 'b';
		octave = 6;
	} else if (freq > 2034.265 && freq < 2155.23) {
		note = 'c';
		octave = 7;
	} else if (freq > 2155.23 && freq < 2283.39) {
		note = 'db';
		octave = 7;
	} else if (freq > 2283.39 && freq < 2419.17) {
		note = 'd';
		octave = 7;
	} else if (freq > 2419.17 && freq < 2563.02) {
		note = 'eb';
		octave = 7;
	} else if (freq > 2563.02 && freq < 2715.425) {
		note = 'e';
		octave = 7;
	} else if (freq > 2715.425 && freq < 2876.895) {
		note = 'f';
		octave = 7;
	} else if (freq > 2876.895 && freq < 3047.96) {
		note = 'gb';
		octave = 7;
	} else if (freq > 3047.96 && freq < 3229.2) {
		note = 'g';
		octave = 7;
	} else if (freq > 3229.2 && freq < 3421.22) {
		note = 'ab';
		octave = 7;
	} else if (freq > 3421.22 && freq < 3624.655) {
		note = 'a';
		octave = 7;
	} else if (freq > 3624.655 && freq < 3840.19) {
		note = 'bb';
		octave = 7;
	} else if (freq > 3840.19 && freq < 4068.54) {
		note = 'b';
		octave = 7;
	} else if (freq > 4068.54 && freq < 4310.465) {
		note = 'c';
		octave = 8;
	} else if (freq > 4310.465 && freq < 4566.775) {
		note = 'db';
		octave = 8;
	} else if (freq > 4566.775 && freq < 4838.33) {
		note = 'd';
		octave = 8;
	} else if (freq > 4838.33 && freq < 5126.035) {
		note = 'eb';
		octave = 8;
	} else if (freq > 5126.035 && freq < 5430.845) {
		note = 'e';
		octave = 8;
	} else if (freq > 5430.845 && freq < 5753.78) {
		note = 'f';
		octave = 8;
	} else if (freq > 5753.78 && freq < 6095.92) {
		note = 'gb';
		octave = 8;
	} else if (freq > 6095.92 && freq < 6458.405) {
		note = 'g';
		octave = 8;
	} else if (freq > 6458.405 && freq < 6842.44) {
		note = 'ab';
		octave = 8;
	} else if (freq > 6842.44 && freq < 7249.31) {
		note = 'a';
		octave = 8;
	} else if (freq > 7249.31 && freq < 7680.375) {
		note = 'bb';
		octave = 8;
	} else if (freq > 7680.375) {
		note = 'b';
		octave = 8;
	}

	if (note.charAt(0) == 'a') {
		note = 'a';
		if (note.length == 2) {
			symbol = 'b';
		} else {
			symbol = null;
		}
	} else if (note.charAt(0) == 'b') {
		note = 'b';
		if (note.length == 2) {
			symbol = 'b';
		} else {
			symbol = null;
		}
	} else if (note.charAt(0) == 'c') {
		note = 'c';
		if (note.length == 2) {
			symbol = 'b';
		} else {
			symbol = null;
		}
	} else if (note.charAt(0) == 'd') {
		note = 'd';
		if (note.length == 2) {
			symbol = 'b';
		} else {
			symbol = null;
		}
	} else if (note.charAt(0) == 'e') {
		note = 'e';
		if (note.length == 2) {
			symbol = 'b';
		} else {
			symbol = null;
		}
	} else if (note.charAt(0) == 'f') {
		note = 'f';
		if (note.length == 2) {
			symbol = 'b';
		} else {
			symbol = null;
		}
	} else if (note.charAt(0) == 'g') {
		note = 'g';
		if (note.length == 2) {
			symbol = 'b';
		} else {
			symbol = null;
		}
	}
	return [note, symbol, octave];
}

function frequencyParserHumanVoice(freq) {
	let note;
	let octave;
	let symbol;
	if (freq < 47.625) {
		note = undefined;
	} else if (freq > 47.625 && freq < 50.455) {
		note = 'g';
		octave = 1;
	} else if (freq > 50.455 && freq < 53.455) {
		note = 'ab';
		octave = 1;
	} else if (freq > 53.455 && freq < 56.635) {
		note = 'a';
		octave = 1;
	} else if (freq > 56.635 && freq < 60.005) {
		note = 'bb';
		octave = 1;
	} else if (freq > 60.005 && freq < 63.575) {
		note = 'b';
		octave = 1;
	} else if (freq > 63.575 && freq < 67.355) {
		note = 'c';
		octave = 2;
	} else if (freq > 67.355 && freq < 71.36) {
		note = 'db';
		octave = 2;
	} else if (freq > 71.36 && freq < 75.6) {
		note = 'd';
		octave = 2;
	} else if (freq > 75.6 && freq < 80.095) {
		note = 'eb';
		octave = 2;
	} else if (freq > 80.095 && freq < 84.86) {
		note = 'e';
		octave = 2;
	} else if (freq > 84.86 && freq < 89.905) {
		note = 'f';
		octave = 2;
	} else if (freq > 89.905 && freq < 95.25) {
		note = 'gb';
		octave = 2;
	} else if (freq > 95.25 && freq < 100.915) {
		note = 'g';
		octave = 2;
	} else if (freq > 100.915 && freq < 106.915) {
		note = 'ab';
		octave = 2;
	} else if (freq > 106.915 && freq < 113.27) {
		note = 'a';
		octave = 2;
	} else if (freq > 113.27 && freq < 120.005) {
		note = 'bb';
		octave = 2;
	} else if (freq > 120.005 && freq < 127.14) {
		note = 'b';
		octave = 2;
	} else if (freq > 127.14 && freq < 134.7) {
		note = 'c';
		octave = 3;
	} else if (freq > 134.7 && freq < 142.71) {
		note = 'db';
		octave = 3;
	} else if (freq > 142.71 && freq < 151.195) {
		note = 'd';
		octave = 3;
	} else if (freq > 151.195 && freq < 160.185) {
		note = 'eb';
		octave = 3;
	} else if (freq > 160.185 && freq < 169.71) {
		note = 'e';
		octave = 3;
	} else if (freq > 169.71 && freq < 179.805) {
		note = 'f';
		octave = 3;
	} else if (freq > 179.805 && freq < 190.5) {
		note = 'gb';
		octave = 3;
	} else if (freq > 190.5 && freq < 201.825) {
		note = 'g';
		octave = 3;
	} else if (freq > 201.825 && freq < 213.825) {
		note = 'ab';
		octave = 3;
	} else if (freq > 213.825 && freq < 226.54) {
		note = 'a';
		octave = 3;
	} else if (freq > 226.54 && freq < 240.01) {
		note = 'bb';
		octave = 3;
	} else if (freq > 240.01 && freq < 254.285) {
		note = 'b';
		octave = 3;
	} else if (freq > 254.285 && freq < 269.405) {
		note = 'c';
		octave = 4;
	} else if (freq > 269.405 && freq < 285.42) {
		note = 'db';
		octave = 4;
	} else if (freq > 285.42 && freq < 302.395) {
		note = 'd';
		octave = 4;
	} else if (freq > 302.395 && freq < 320.38) {
		note = 'eb';
		octave = 4;
	} else if (freq > 320.38 && freq < 339.43) {
		note = 'e';
		octave = 4;
	} else if (freq > 339.43 && freq < 359.61) {
		note = 'f';
		octave = 4;
	} else if (freq > 359.61 && freq < 380.995) {
		note = 'gb';
		octave = 4;
	} else if (freq > 380.995 && freq < 403.65) {
		note = 'g';
		octave = 4;
	} else if (freq > 403.65 && freq < 427.65) {
		note = 'ab';
		octave = 4;
	} else if (freq > 427.65 && freq < 453.08) {
		note = 'a';
		octave = 4;
	} else if (freq > 453.08 && freq < 480.02) {
		note = 'bb';
		octave = 4;
	} else if (freq > 480.02 && freq < 508.565) {
		note = 'b';
		octave = 4;
	} else if (freq > 508.565 && freq < 538.81) {
		note = 'c';
		octave = 5;
	} else if (freq > 538.81 && freq < 570.85) {
		note = 'db';
		octave = 5;
	} else if (freq > 570.85 && freq < 604.79) {
		note = 'd';
		octave = 5;
	} else if (freq > 604.79 && freq < 640.75) {
		note = 'eb';
		octave = 5;
	} else if (freq > 640.75 && freq < 678.855) {
		note = 'e';
		octave = 5;
	} else if (freq > 678.855 && freq < 719.225) {
		note = 'f';
		octave = 5;
	} else if (freq > 719.225 && freq < 761.99) {
		note = 'gb';
		octave = 5;
	} else if (freq > 761.99 && freq < 807.3) {
		note = 'g';
		octave = 5;
	} else if (freq > 807.3 && freq < 855.305) {
		note = 'ab';
		octave = 5;
	} else if (freq > 855.305 && freq < 906.165) {
		note = 'a';
		octave = 5;
	} else if (freq > 906.165 && freq < 960.05) {
		note = 'bb';
		octave = 5;
	} else if (freq > 960.05 && freq < 1017.135) {
		note = 'b';
		octave = 5;
	} else if (freq > 1017.135 && freq < 1077.615) {
		note = 'c';
		octave = 6;
	} else if (freq > 1077.615 && freq < 1141.695) {
		note = 'db';
		octave = 6;
	} else if (freq > 1141.695 && freq < 1209.585) {
		note = 'd';
		octave = 6;
	} else if (freq > 1209.585 && freq < 1281.51) {
		note = 'eb';
		octave = 6;
	} else if (freq > 1281.51 && freq < 1357.71) {
		note = 'e';
		octave = 6;
	} else if (freq > 1357.71 && freq < 1438.445) {
		note = 'f';
		octave = 6;
	} else if (freq > 1438.445 && freq < 1523.98) {
		note = 'gb';
		octave = 6;
	} else if (freq > 1523.98 && freq < 1614.6) {
		note = 'g';
		octave = 6;
	} else if (freq > 1614.6 && freq < 1710.61) {
		note = 'ab';
		octave = 6;
	} else if (freq > 1710.61 && freq < 1812.33) {
		note = 'a';
		octave = 6;
	} else if (freq > 1812.33 && freq < 1920.095) {
		note = 'bb';
		octave = 6;
	} else if (freq > 1920.095 && freq < 2034.265) {
		note = 'b';
		octave = 6;
	} else if (freq > 2034.265 && freq < 2155.23) {
		note = 'c';
		octave = 7;
	} else if (freq > 2155.23 && freq < 2283.39) {
		note = 'db';
		octave = 7;
	} else if (freq > 2283.39 && freq < 2419.17) {
		note = 'd';
		octave = 7;
	} else if (freq > 2419.17 && freq < 2563.02) {
		note = 'eb';
		octave = 7;
	} else if (freq > 2563.02 && freq < 2715.425) {
		note = 'e';
		octave = 7;
	} else if (freq > 2715.425 && freq < 2876.895) {
		note = 'f';
		octave = 7;
	} else if (freq > 2876.895 && freq < 3047.96) {
		note = 'gb';
		octave = 7;
	} else if (freq > 3047.96) {
		note = undefined;
	}

	if (note == undefined) {
		return [undefined, undefined, undefined];
	} else if (note.charAt(0) == 'a') {
		note = 'a';
		if (note.length == 2) {
			symbol = 'b';
		} else {
			symbol = null;
		}
	} else if (note.charAt(0) == 'b') {
		note = 'b';
		if (note.length == 2) {
			symbol = 'b';
		} else {
			symbol = null;
		}
	} else if (note.charAt(0) == 'c') {
		note = 'c';
		if (note.length == 2) {
			symbol = 'b';
		} else {
			symbol = null;
		}
	} else if (note.charAt(0) == 'd') {
		note = 'd';
		if (note.length == 2) {
			symbol = 'b';
		} else {
			symbol = null;
		}
	} else if (note.charAt(0) == 'e') {
		note = 'e';
		if (note.length == 2) {
			symbol = 'b';
		} else {
			symbol = null;
		}
	} else if (note.charAt(0) == 'f') {
		note = 'f';
		if (note.length == 2) {
			symbol = 'b';
		} else {
			symbol = null;
		}
	} else if (note.charAt(0) == 'g') {
		note = 'g';
		if (note.length == 2) {
			symbol = 'b';
		} else {
			symbol = null;
		}
	}
	return [note, symbol, octave];
}

function frequencyParserToRealFreq(freq) {
	let realFreq;
	if (freq < 16.835) {
		realFreq = 16.35;
	} else if (freq > 16.835 && freq < 17.835) {
		realFreq = 17.32;
	} else if (freq > 17.835 && freq < 18.9) {
		realFreq = 18.35;
	} else if (freq > 18.9 && freq < 20.025) {
		realFreq = 19.45;
	} else if (freq > 20.025 && freq < 21.215) {
		realFreq = 20.6;
	} else if (freq > 21.215 && freq < 22.475) {
		realFreq = 21.83;
	} else if (freq > 22.475 && freq < 23.81) {
		realFreq = 23.12;
	} else if (freq > 23.81 && freq < 25.23) {
		realFreq = 24.5;
	} else if (freq > 25.23 && freq < 26.73) {
		realFreq = 25.96;
	} else if (freq > 26.73 && freq < 28.32) {
		realFreq = 27.5;
	} else if (freq > 28.32 && freq < 30.005) {
		realFreq = 29.14;
	} else if (freq > 30.005 && freq < 31.785) {
		realFreq = 30.87;
	} else if (freq > 31.785 && freq < 33.675) {
		realFreq = 32.7;
	} else if (freq > 33.675 && freq < 35.68) {
		realFreq = 34.65;
	} else if (freq > 35.68 && freq < 37.8) {
		realFreq = 36.71;
	} else if (freq > 37.8 && freq < 40.045) {
		realFreq = 38.89;
	} else if (freq > 40.045 && freq < 42.425) {
		realFreq = 41.2;
	} else if (freq > 42.425 && freq < 44.95) {
		realFreq = 43.65;
	} else if (freq > 44.95 && freq < 47.625) {
		realFreq = 46.25;
	} else if (freq > 47.625 && freq < 50.455) {
		realFreq = 49;
	} else if (freq > 50.455 && freq < 53.455) {
		realFreq = 51.91;
	} else if (freq > 53.455 && freq < 56.635) {
		realFreq = 55;
	} else if (freq > 56.635 && freq < 60.005) {
		realFreq = 58.27;
	} else if (freq > 60.005 && freq < 63.575) {
		realFreq = 61.74;
	} else if (freq > 63.575 && freq < 67.355) {
		realFreq = 65.41;
	} else if (freq > 67.355 && freq < 71.36) {
		realFreq = 69.3;
	} else if (freq > 71.36 && freq < 75.6) {
		realFreq = 73.42;
	} else if (freq > 75.6 && freq < 80.095) {
		realFreq = 77.78;
	} else if (freq > 80.095 && freq < 84.86) {
		realFreq = 82.41;
	} else if (freq > 84.86 && freq < 89.905) {
		realFreq = 87.31;
	} else if (freq > 89.905 && freq < 95.25) {
		realFreq = 92.5;
	} else if (freq > 95.25 && freq < 100.915) {
		realFreq = 98;
	} else if (freq > 100.915 && freq < 106.915) {
		realFreq = 103.83;
	} else if (freq > 106.915 && freq < 113.27) {
		realFreq = 110;
	} else if (freq > 113.27 && freq < 120.005) {
		realFreq = 116.54;
	} else if (freq > 120.005 && freq < 127.14) {
		realFreq = 123.47;
	} else if (freq > 127.14 && freq < 134.7) {
		realFreq = 130.81;
	} else if (freq > 134.7 && freq < 142.71) {
		realFreq = 138.59;
	} else if (freq > 142.71 && freq < 151.195) {
		realFreq = 146.83;
	} else if (freq > 151.195 && freq < 160.185) {
		realFreq = 155.56;
	} else if (freq > 160.185 && freq < 169.71) {
		realFreq = 164.81;
	} else if (freq > 169.71 && freq < 179.805) {
		realFreq = 174.61;
	} else if (freq > 179.805 && freq < 190.5) {
		realFreq = 185;
	} else if (freq > 190.5 && freq < 201.825) {
		realFreq = 196;
	} else if (freq > 201.825 && freq < 213.825) {
		realFreq = 207.65;
	} else if (freq > 213.825 && freq < 226.54) {
		realFreq = 220;
	} else if (freq > 226.54 && freq < 240.01) {
		realFreq = 233.08;
	} else if (freq > 240.01 && freq < 254.285) {
		realFreq = 246.94;
	} else if (freq > 254.285 && freq < 269.405) {
		realFreq = 261.63;
	} else if (freq > 269.405 && freq < 285.42) {
		realFreq = 277.18;
	} else if (freq > 285.42 && freq < 302.395) {
		realFreq = 293.66;
	} else if (freq > 302.395 && freq < 320.38) {
		realFreq = 311.13;
	} else if (freq > 320.38 && freq < 339.43) {
		realFreq = 329.63;
	} else if (freq > 339.43 && freq < 359.61) {
		realFreq = 349.23;
	} else if (freq > 359.61 && freq < 380.995) {
		realFreq = 369.99;
	} else if (freq > 380.995 && freq < 403.65) {
		realFreq = 392;
	} else if (freq > 403.65 && freq < 427.65) {
		realFreq = 415.3;
	} else if (freq > 427.65 && freq < 453.08) {
		realFreq = 440;
	} else if (freq > 453.08 && freq < 480.02) {
		realFreq = 466.16;
	} else if (freq > 480.02 && freq < 508.565) {
		realFreq = 493.88;
	} else if (freq > 508.565 && freq < 538.81) {
		realFreq = 523.25;
	} else if (freq > 538.81 && freq < 570.85) {
		realFreq = 554.37;
	} else if (freq > 570.85 && freq < 604.79) {
		realFreq = 587.33;
	} else if (freq > 604.79 && freq < 640.75) {
		realFreq = 622.25;
	} else if (freq > 640.75 && freq < 678.855) {
		realFreq = 659.25;
	} else if (freq > 678.855 && freq < 719.225) {
		realFreq = 698.46;
	} else if (freq > 719.225 && freq < 761.99) {
		realFreq = 739.99;
	} else if (freq > 761.99 && freq < 807.3) {
		realFreq = 783.99;
	} else if (freq > 807.3 && freq < 855.305) {
		realFreq = 830.61;
	} else if (freq > 855.305 && freq < 906.165) {
		realFreq = 880;
	} else if (freq > 906.165 && freq < 960.05) {
		realFreq = 932.33;
	} else if (freq > 960.05 && freq < 1017.135) {
		realFreq = 987.77;
	} else if (freq > 1017.135 && freq < 1077.615) {
		realFreq = 1046.5;
	} else if (freq > 1077.615 && freq < 1141.695) {
		realFreq = 1108.73;
	} else if (freq > 1141.695 && freq < 1209.585) {
		realFreq = 1174.66;
	} else if (freq > 1209.585 && freq < 1281.51) {
		realFreq = 1244.51;
	} else if (freq > 1281.51 && freq < 1357.71) {
		realFreq = 1318.51;
	} else if (freq > 1357.71 && freq < 1438.445) {
		realFreq = 1396.91;
	} else if (freq > 1438.445 && freq < 1523.98) {
		realFreq = 1479.98;
	} else if (freq > 1523.98 && freq < 1614.6) {
		realFreq = 1567.98;
	} else if (freq > 1614.6 && freq < 1710.61) {
		realFreq = 1661.22;
	} else if (freq > 1710.61 && freq < 1812.33) {
		realFreq = 1760;
	} else if (freq > 1812.33 && freq < 1920.095) {
		realFreq = 1864.66;
	} else if (freq > 1920.095 && freq < 2034.265) {
		realFreq = 1975.53;
	} else if (freq > 2034.265 && freq < 2155.23) {
		realFreq = 2093;
	} else if (freq > 2155.23 && freq < 2283.39) {
		realFreq = 2217.46;
	} else if (freq > 2283.39 && freq < 2419.17) {
		realFreq = 2349.32;
	} else if (freq > 2419.17 && freq < 2563.02) {
		realFreq = 2489.02;
	} else if (freq > 2563.02 && freq < 2715.425) {
		realFreq = 2637.02;
	} else if (freq > 2715.425 && freq < 2876.895) {
		realFreq = 2793.83;
	} else if (freq > 2876.895 && freq < 3047.96) {
		realFreq = 2959.96;
	} else if (freq > 3047.96 && freq < 3229.2) {
		realFreq = 3135.96;
	} else if (freq > 3229.2 && freq < 3421.22) {
		realFreq = 3322.44;
	} else if (freq > 3421.22 && freq < 3624.655) {
		realFreq = 3520;
	} else if (freq > 3624.655 && freq < 3840.19) {
		realFreq = 3729.31;
	} else if (freq > 3840.19 && freq < 4068.54) {
		realFreq = 3951.07;
	} else if (freq > 4068.54 && freq < 4310.465) {
		realFreq = 4186.01;
	} else if (freq > 4310.465 && freq < 4566.775) {
		realFreq = 4434.92;
	} else if (freq > 4566.775 && freq < 4838.33) {
		realFreq = 4698.63;
	} else if (freq > 4838.33 && freq < 5126.035) {
		realFreq = 4978.03;
	} else if (freq > 5126.035 && freq < 5430.845) {
		realFreq = 5274.04;
	} else if (freq > 5430.845 && freq < 5753.78) {
		realFreq = 5587.65;
	} else if (freq > 5753.78 && freq < 6095.92) {
		realFreq = 5919.91;
	} else if (freq > 6095.92 && freq < 6458.405) {
		realFreq = 6271.93;
	} else if (freq > 6458.405 && freq < 6842.44) {
		realFreq = 6644.88;
	} else if (freq > 6842.44 && freq < 7249.31) {
		realFreq = 7040;
	} else if (freq > 7249.31 && freq < 7680.375) {
		realFreq = 7458.62;
	} else if (freq > 7680.375) {
		realFreq = 7902.13;
	}
	return realFreq;
}

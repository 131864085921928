<script>
	import { createEventDispatcher } from 'svelte';
  import SVGImage from "./SVGImage.svelte";
  import lock from "../assets/icons/lock.svg";

  export let sheet;

  const dispatch = createEventDispatcher();


  function play() {
    dispatch('play');
	}

</script>

<div class="bg-zinc-700 rounded-lg m-4 mx-4 p-4">
  <div class="grid grid-cols-2 py-3">
    <!-- First Column -->
    <div class="flex gap-5 items-center">
      <div class="space-y-2">
        <h3 class="text-lg font-semibold text-white-100 leading-none tracking-wide">{sheet.title}</h3>
        <p class="text-sm font-light leading-none text-white-200/50">Puntuación máxima: {sheet.getBestScore() ? Math.round(sheet.getBestScore().score ) : '--'}</p>
      </div>
    </div>

    <!-- Second Column with Play Button -->
    <div class="flex items-center justify-end">
      {#if sheet.unlocked}
        <button on:click={() => play()} class="bg-zinc-800 text-white rounded-md px-4 py-2 hover:bg-zinc-600">Play</button>
      {:else}
        <div class="relative w-12 h-12 overflow-hidden flex justify-center items-center shrink-0">
          <SVGImage imagePath="{lock}" />
        </div>
      {/if}
    </div>
  </div>
</div>

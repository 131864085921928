import * as Meyda from 'meyda';
import * as load from 'audio-loader';
import * as wav from 'node-wav';
import KNN from 'ml-knn';
import { doremi } from '../store';
import { get } from 'svelte/store';
import * as tf from '@tensorflow/tfjs';
import { PitchDetector } from 'pitchy';
import { notifications } from '../toast/Notifications';

import { modifyOctave } from "../modules/storeManager";
import { evaluateNote } from "../modules/levelEvaluationManager";
/* THERE ARE THREE DIFFERENT MODES:

1-DICTATION MODE: YOU HAVE TO STOP BETWEEN DICTATIONS/HUMMINGS LO LET THE PROGRAM THINK TO
2-SPEED DICTATION MODE: YOU CAN DICTATE/HUM THINGS IN ANY WANTED VELOCITY
3-REAL TIME MODE: GIVEN A TEMPO, CATCHES EVEN RITHM 

*/

// MEYDA SETUP
var DEFAULT_MFCC_VALUE = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
var FEATURE_NAME_MFCC = 'mfcc';
var FEATURE_NAME_RMS = 'rms';

var THRESHOLD_RMS_DICTATION = 0.002; //0.002 as default threshold on rms value (0.002 for speech recog)

var cur_mfcc = DEFAULT_MFCC_VALUE;
var cur_rms = 0;
var mfcc_history = [];

//MYSETUP FOR AUDIO
var mfccStacked = [];
var frequencyStacked = [];
var isAnalizedStack = true;
var startMfcc = false;
var mfccNumber = 0;

let analyzer;
let currentFrequency;
var silence = true;
//PARA MOVIL
//let doremiModel= KNN.load(model);

//newKnn=KNN.load(model);

export function startSpeechRecognizer() {
	notifications.grey('¡Norelearn te esta escuchando!', 1500);
	onMicDataCall([FEATURE_NAME_MFCC, FEATURE_NAME_RMS], show)
		.then((meydaAnalyzer) => {
			meydaAnalyzer.start();
		})
		.catch((err) => {
			alert(err);
		});
}

export function stopSpeechRecognizer() {
	//notifications.grey('Stopped Speech Recognition...', 1500);
	if (analyzer) {
		analyzer.stop();
	}
}

export function setSensitivity(number) {
	THRESHOLD_RMS = number;
}

//Fastmode
function show(features) {
	// update spectral data size

	cur_mfcc = features[FEATURE_NAME_MFCC];
	cur_rms = features[FEATURE_NAME_RMS];

	if (cur_rms > THRESHOLD_RMS_DICTATION && mfccNumber == 0) {
		startMfcc = true;
		mfccStacked = mfccStacked.concat(cur_mfcc);
		mfcc_history.push(cur_mfcc);
		silence = false;
		mfccNumber++;
	}

	if (startMfcc == true && mfccNumber <= 15) {
		startMfcc = true;
		mfccStacked = mfccStacked.concat(cur_mfcc);
		mfcc_history.push(cur_mfcc);
		silence = false;
		mfccNumber++;
	}

	if (mfccNumber > 15 && mfccNumber < 31 && startMfcc == true) {
		for (let i = 0; i < 31 - mfccNumber; i++) {
			let auxArray = [
				0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0, 0.0,
			];
			mfccStacked = mfccStacked.concat(auxArray);
		}
		//mfccStacked=mfccStacked.concat(cur_mfcc)
		//mfccNumber++;
		mfccNumber = 31;
		startMfcc = true;
	}
	if (startMfcc == true && mfccNumber == 31) {
		//This is kNN
		//var prediction = doremiModel.predict(mfccStacked)
		//This is CNN
		let prediction = predictDeepLearning(mfccStacked);
		console.log(prediction);
		const notes = ['c', 'd', 'e', 'f', 'g', 'a', 'b'];
		if (prediction < 8) {
			const noteName = notes[prediction - 1];
			console.log('Evaluate note: ' + noteName);
			evaluateNote(noteName);
		} else {
			console.log('noise');
		}


		//console.log("mfccStacked: ")
		//console.log(mfccStacked)
		mfccNumber = 0;
		startMfcc = false;
		mfccStacked = [];
	}
}

function /* get new audio 
context object */
createAudioCtx() {
	let AudioContext = window.AudioContext || window.webkitAudioContext;
	return new AudioContext({
		sampleRate: 16000,
	});
}

function /* create microphone
audio input source from 
audio context */
createMicSrcFrom(audioCtx) {
	/* get microphone access */
	return new Promise((resolve, reject) => {
		/* only audio */
		let constraints = {
			audio: {
				sampleRate: 16000,
				channelCount: 1,
				noiseSuppression: true,
			},
			video: false,
		};
		//noiseSuppression: true,
		navigator.mediaDevices
			.getUserMedia(constraints)
			.then((stream) => {
				/* create source from
            microphone input stream */

				let src = audioCtx.createMediaStreamSource(stream);
				resolve(src);
			})
			.catch((err) => {
				reject(err);
			});
	});
}

function /* call given function
on new microphone analyser
data */
onMicDataCall(features, callback) {
	return new Promise((resolve, reject) => {
		let audioCtx = createAudioCtx();
		createMicSrcFrom(audioCtx)
			.then((src) => {
				analyzer = Meyda.createMeydaAnalyzer({
					audioContext: audioCtx,
					source: src,
					bufferSize: 512,
					sampleRate: 16000,
					windowingFunction: 'hanning',
					numberOfMFCCCoefficients: 13,
					inputs: 1,
					featureExtractors: features,
					callback: callback,
				});
				resolve(analyzer);
			})
			.catch((err) => {
				reject(err);
			});
	});
}

//Fastmode
function showFrequency(features) {
	//console.log(analyser.frequency);

	//cur_mfcc = features[FEATURE_NAME_MFCC]
	cur_rms = features[FEATURE_NAME_RMS];

	if (cur_rms > THRESHOLD_RMS_FREQUENCY) {
		//console.log("entré")
		console.log(currentFrequency);
		frequencyStacked = frequencyStacked.concat(currentFrequency);
	}

	if (cur_rms < THRESHOLD_RMS_FREQUENCY && frequencyStacked.length != 0) {
		let lastValue = frequencyStacked[frequencyStacked.length - 1];
		console.log('lastValue');
		console.log(lastValue);
		let average = 0;
		let kont = 0;
		for (let i = frequencyStacked.length; i >= 0; i--) {
			if (lastValue - 10 < frequencyStacked[i]) {
				lastValue = frequencyStacked[i];
				//console.log("newlastValue")
				//console.log(lastValue)
				average = average + frequencyStacked[i];
				kont++;
			}
		}
		average = average / kont;
		let avg = average.toFixed(2);
		console.log('result: ');
		console.log(avg);

		let result = frequencyParser(avg);

		console.log(result[0]);
		console.log(result[1]);
		console.log(result[2]);
		//This is kNN
		//var prediction = doremiModel.predict(mfccStacked)
		//This is CNN
		modifyOctave(result[2]);
		console.log('add note: ' + result[0]);
		console.log('Evaluate note: ' + result[0]);
		evaluateNote(result[0]);
		frequencyStacked = [];
	}
}

function predictDeepLearning(array) {
	let model = get(doremi);
	let input = tf.tensor(array).reshape([1, 31, 13, 1]);
	//input=input.reshape([1, 65, 13, 1])

	const prediction = model.predict(input);
	/*
	console.log('prediction:');
	console.log(prediction.dataSync()[0]);
	console.log(prediction.dataSync()[1]);
	console.log(prediction.dataSync()[2]);
	console.log(prediction.dataSync()[3]);
	console.log(prediction.dataSync()[4]);
	console.log(prediction.dataSync()[5]);
	console.log(prediction.dataSync()[6]);


	 */
	let best = 0;
	let index = 0;
	for (let i = 0; i < 8; i++) {
		if (prediction.dataSync()[i] > best) {
			best = prediction.dataSync()[i];
			index = i;
		}
	}
	/*
	console.log('result:');
	console.log('best:', best);
	console.log('index:', index);
	 */
	/*const input = tf.tensor([[1,2,3,4,5,6,7,8,9,10,1,2,3,4,5,6,7,8,9,10,1,2,3,4,5,6,7,8,9,10,1,2,3,4,5,6,7,8,9,10,1,2,3,4,5,6,7,8,9,10,1,2,3,4,5,6,7,8,9,10,1,2,3,4,5,6,7,8,9,10,1,2,3,4,5,6,7,8,9,10,1,2,3,4,5,6,7,8,9,10,1,2,3,4,5,6,7,8,9,10,1,2,3,4,5,6,7,8,9,10,1,2,3,4,5,6,7,8,9,10,1,2,3,4,5,6,7,8,9,10,1,2,3,4,5,6,7,8,9,10,1,2,3,4,5,6,7,8,9,10,1,2,3,4,5,6,7,8,9,10,1,2,3,4,5,6,7,8,9,10,1,2,3,4,5,6,7,8,9,10,1,2,3,4,5,6,7,8,9,10,1,2,3,4,5,6,7,8,9,10,1,2,3,4,5,6,7,8,9,10,1,2,3,4,5,6,7,8,9,10,1,2,3,4,5,6,7,8,9,10,1,2,3,4,5,6,7,8,9,10,1,2,3,4,5,6,7,8,9,10,1,2,3,4,5,6,7,8,9,10,1,2,3,4,5,6,7,8,9,10,1,2,3,4,5,6,7,8,9,10,1,2,3,4,5,6,7,8,9,10,1,2,3,4,5,6,7,8,9,10,1,2,3,4,5,6,7,8,9,10,1,2,3,4,5,6,7,8,9,10,1,2,3,4,5,6,7,8,9,10,1,2,3,4,5,6,7,8,9,10,1,2,3,4,5,6,7,8,9,10,1,2,3,4,5,6,7,8,9,10,1,2,3,4,5,6,7,8,9,10,1,2,3,4,5,6,7,8,9,10,1,2,3,4,5,6,7,8,9,10,1,2,3,4,5,6,7,8,9,10,1,2,3,4,5,6,7,8,9,10,1,2,3,4,5,6,7,8,9,10,1,2,3,4,5,6,7,8,9,10,1,2,3,4,5,6,7,8,9,10,1,2,3,4,5,6,7,8,9,10,1,2,3,4,5,6,7,8,9,10,1,2,3,4,5,6,7,8,9,10,1,2,3,4,5,6,7,8,9,10,1,2,3,4,5,6,7,8,9,10,1,2,3,4,5,6,7,8,9,10,1,2,3,4,5,6,7,8,9,10,1,2,3,4,5,6,7,8,9,10,1,2,3,4,5,6,7,8,9,10,1,2,3,4,5,6,7,8,9,10,1,2,3,4,5,6,7,8,9,10,1,2,3,4,5,6,7,8,9,10,1,2,3,4,5,6,7,8,9,10,1,2,3,4,5,6,7,8,9,10,1,2,3,4,5,6,7,8,9,10,1,2,3,4,5,6,7,8,9,10,1,2,3,4,5,6,7,8,9,10,1,2,3,4,5,6,7,8,9,10,1,2,3,4,5,6,7,8,9,10,1,2,3,4,5,6,7,8,9,10,1,2,3,4,5,6,7,8,9,10,1,2,3,4,5,6,7,8,9,10,1,2,3,4,5,6,7,8,9,10,1,2,3,4,5,6,7,8,9,10,1,2,3,4,5,6,7,8,9,10,1,2,3,4,5,6,7,8,9,10,1,2,3,4,5,6,7,8,9,10,1,2,3,4,5,6,7,8,9,10,1,2,3,4,5,6,7,8,9,10,1,2,3,4,5,6,7,8,9,10,1,2,3,4,5,6,7,8,9,10,1,2,3,4,5,6,7,8,9,10,1,2,3,4,5,6,7,8,9,10,1,2,3,4,5,6,7,8,9,10,1,2,3,4]]);
	const prediction = model.predict(input.reshape([65,13,1]));
	const result = await prediction.argMax(1).data();
	return result[0];*/
	return index + 1;
}

<script>
	import redonda from "../assets/icons/redonda.svg";
	import blanca from "../assets/icons/blanca.svg";
	import negra from "../assets/icons/negra.svg";
	import corchea from "../assets/icons/corchea.svg";
	import semicorchea from "../assets/icons/semicorchea.svg";	
	import playButton from "../assets/icons/playButton.svg";
	import send6 from "../assets/icons/send6.svg";
	import { openModal } from 'svelte-modals'
	import SaveSheet from '../components/EditorSavesheet.svelte'
	import Vex from 'vexflow';
	import { onMount, onDestroy } from 'svelte';
	import { replace } from 'svelte-spa-router';
	import {
		contextStore,
		currentMusicSheetStore,
		downloadedSheetStore,
		frequencyEnabledStore,
		currentlyListeningStore,
		editModeStore,
		saveSheetStore,
		realTimeRunningStore, currentFigureStore
	} from "../store";
	import { scrollCountStore } from "../stores/ScrollStore";
	import { get } from 'svelte/store';
	import {
		startSpeechRecognizer,
		stopSpeechRecognizer,
	} from '../recognition/speechRecognizer.js';
	import {
		startFrequencyRecognizer,
		stopFrequencyRecognizer,
	} from '../recognition/frequencyRecognizer.js';
	import {
		startFrequencyRecognizerRealTime,
		stopFrequencyRecognizerRealTime,
		stopFrequencyRecognizerRealTimeForDebugging,
		printSpeech,
	} from '../recognition/frequencyRecognizerRealTime.js';

	//JSON TO XML
	import { json2xml } from '../json2xml.js';
	import { playAll } from '../audio/playback.js';
	import {
		speechCommandModel,
		lstmNorewingModel,
		uploadSheet,
		parseKeys,
		storeHumanSheetLSTM,
		uploadSheetToVAEDataset
	} from "../Editor.ts";
	import {Alteration, getAccidentalFromString, getAccidentalNameFromAlteration} from "../model/Note";
	import SVGImage from "../components/SVGImage.svelte";
	import BackArrow from "../components/BackArrow.svelte";
	import Toast from "../toast/Toast.svelte";
	import {notifications} from '../toast/Notifications'
	import {
		updateCurrentAccidental,
		updateCurrentFigure,
		updateCurrentFigureWithPuntillo, updateOctave
	} from "../modules/storeManager.js";
	import { addNoteWithOctave } from "../modules/editNoteManager.js";
	import { cleanCanvasAndData, initSheet } from "../modules/sheetManager.js";
	import { MEASURE_WIDTH } from "utils/contextConstants";

	$: active = $saveSheetStore;

		//This function is used to check whether it comes from tempo selection
	function runFirstRealTimeRecognition () {
		//if it is true it will run it 
		startFrequencyRecognizerRealTime();
		const isRunning = get(realTimeRunningStore);
		if (isRunning==true){
			realTimeRunningStore.update(() => {
			  return false;
		  });
			//startFrequencyRecognizerRealTime();
		}      
	}

	onMount(() => {
		const portrait = window.matchMedia("(orientation: portrait)").matches;
		if (portrait) {
			notifications.grey('Por favor, gira tu dispositivo para una mejor experiencia.', 3000);
		}
		let context = get(contextStore);
		const VF = Vex.Flow;
		const div = document.getElementById('vf');
		const renderer = new VF.Renderer(div, VF.Renderer.Backends.SVG);
		context = renderer.getContext();

		context.setFont('Arial', 10, '').setBackgroundFillStyle('#eed');

		contextStore.update(() => {
			return context;
		});
		updateCurrentFigure('Negra');
		const hiddenButton = document.getElementById('hiddenButton');
		const hiddenButton2 = document.getElementById('hiddenButton2');
		const hiddenButton3 = document.getElementById('hiddenButton3');
		const recordingButton = document.getElementById('recordingButton');
		
		

		//get sheet
		// downloadSheet();
		let currentSheet = get(currentMusicSheetStore);
  		if (currentSheet == null) {
			replace('/');
			return () => {};
  		}
		initSheet();
		listenToScrollNeed();
		return () => {};
	});

	onDestroy(() => {
		scrollCountStore.set(0);
		stopRecognitionSpeech();
	});

	function listenToScrollNeed() {
		scrollCountStore.subscribe((value) => {
			if (value > 0) {
				scroll(value);
			}
		});
	}

	function scroll(value){
		const sheetContainer = document.getElementById("scrollable-container");
		sheetContainer.scrollTo({
			left: value * (MEASURE_WIDTH / 4),
			behavior: 'smooth'
		});
	}

	const speech = speechCommandModel();
	//const lstm = lstmNorewingModel();
	if (speech != null) { //&& lstm != null
		notifications.green('¡Estamos listos para escucharte! Inicia cuando desees.', 1500);
	} else {
		notifications.red('¡Oops! Parece que hay un problema técnico. Por favor, inténtalo de nuevo más tarde o ponte en contacto con soporte.', 1500);
	}
	//RECORDER START////////////////////////////////////////////////
	//TODO: Pasar esto a un nuevo .js en el que esten todas las funcionalidades como escuchar microfono, hablar al modelo de IA... (que no sea Editor.ts)
	
	let constraintObj = {
		audio: true,
		video: false,
	};

	//handle older browsers that might implement getUserMedia in some way
	if (navigator.mediaDevices === undefined) {
		navigator.mediaDevices = {};
		navigator.mediaDevices.getUserMedia = function (constraintObj) {
			let getUserMedia =
				navigator.getUserMedia ||
				navigator.webkitGetUserMedia ||
				navigator.mozGetUserMedia ||
				navigator.msGetUserMedia;
			if (!getUserMedia) {
				return Promise.reject(
					new Error('getUserMedia is not implemented in this browser')
				);
			}
			return new Promise(function (resolve, reject) {
				getUserMedia.call(navigator, constraintObj, resolve, reject);
			});
		};
	} else {
		navigator.mediaDevices
			.enumerateDevices()
			.then((devices) => {
				devices.forEach((device) => {
					console.log(device.kind.toUpperCase(), device.label);
					//, device.deviceId
				});
			})
			.catch((err) => {
				console.log(err.name, err.message);
			});
	}

	navigator.mediaDevices
		.getUserMedia(constraintObj)
		.then(function (mediaStreamObj) {
			//connect the media stream to the first video element
			let audio = document.querySelector('audio');
			if ('srcObject' in audio) {
				audio.srcObject = mediaStreamObj;
			} else {
				//old version
				audio.src = window.URL.createObjectURL(mediaStreamObj);
			}

			/*audio.onloadedmetadata = function(ev) {
                //show in the video element what is being captured by the webcam
                audio.play();
            };*/

			//add listeners for saving video/audio
			let start = document.getElementById('btnStart');
			let stop = document.getElementById('btnStop');
			let options = {
				audioBitsPerSecond: 48000,
				//mimeType: 'audio/wav'
				//mimeType : 'audio/ogg'
			};
			let mediaRecorder = new MediaRecorder(mediaStreamObj, options);
			let chunks = [];

			start.addEventListener('click', () => {
				startSpeechRecognizer();
			});

			stop.addEventListener('click', () => {
				stopSpeechRecognizer();
			});
			mediaRecorder.ondataavailable = function (ev) {
				chunks.push(ev.data);
			};
			mediaRecorder.onstop = () => {};
		})
		.catch(function (err) {
			console.log(err.name, err.message);
		});
	/*********************************
     getUserMedia returns a Promise
     resolve - returns a MediaStream Object
     reject returns one of the following errors
     AbortError - generic unknown cause
     NotAllowedError (SecurityError) - user rejected permissions
     NotFoundError - missing media track
     NotReadableError - user permissions given but hardware/OS error
     OverconstrainedError - constraint video settings preventing
     TypeError - audio: false, video: false
     *********************************/
	//RECORDER END////////////////////////////////////////////////

	//MAIN CODE START///////////////////////////////////////////

	var myObj = {
		'score-partwise': { '@version': '4.0', 'part-list': null },
	};
	let resultado = json2xml(myObj, '');
	console.log(resultado);
	let color = 'hidden';

	export function open() {
		active = true;
	}
	export function close() {
		active = false;
	}

	$: title = '';
	$: subtitle = '';

	export function closeSaving() {
		console.log(title);
		uploadSheet(title, subtitle);
		active = false;
	}

	//XML TO JSON
	/*import {parseXml} from './parseXml.js';
    var xml = '<?xml version="1.0" encoding="UTF-8" standalone="no"?><!DOCTYPE score-partwise PUBLIC "-//Recordare//DTD MusicXML 4.0 Partwise//EN" "http://www.musicxml.org/dtds/partwise.dtd"><score-partwise version="4.0"><part-list></part-list></score-partwise>';
    var dom = parseXml(xml);
    let resultado=xml2json(dom,"");
    console.log(resultado);*/
	
	let isPuntilloSelected = false;
let selectedAlteration = Alteration.NONE;
	const selectAccidental = (value) => {
		let newAlteration = getAccidentalFromString(value);
		if (selectedAlteration === newAlteration) {
			selectedAlteration = Alteration.NONE;
		} else {
			selectedAlteration = value
		}
		updateCurrentAccidental(selectedAlteration)
	};

	const selectPuntillo = () => {
		isPuntilloSelected = !isPuntilloSelected;
		const currentFigure = get(currentFigureStore);
		if (currentFigure != null) {
			let figureName = currentFigure.name;
			if (!isPuntilloSelected) {
				figureName = figureName.replace('Puntillo', '')
			}
			updateCurrentFigureWithPuntillo(figureName, isPuntilloSelected);
		}
	};
	const frequencyStart = () => {
		startFrequencyRecognizer();
	};

	const frequencyStop = () => {
		stopFrequencyRecognizer();
	};

	const frequencyRealTimeStart = () => {
		startFrequencyRecognizerRealTime();
	};
	const frequencyRealTimeStop = () => {
		stopFrequencyRecognizerRealTime();
		showAndQuantize();
	};

	const deepModel = () => {
		//const result = predict(myModel);
		//console.log(result);
	};
	const playAllMusic = () => {
		console.log("Playing...")
		playAll();
		console.log("Finished playing :)")
	};

	const showAndQuantize = () => {
		color = 'visible';
	};

	const hideAndQuantize = () => {
		color = 'hidden';
		hiddenButton3.style.display = 'block';
		notifications.green('Please, correct the prediction and tap on thumbs up. Your correction will help Norelearn be smarter!!', 10000);
	};
	
	const editMode = get(editModeStore);

	let currentlyListening = false;

	let currentValue = '';
	
	const updateCurrentFigureAndListen = (value) => {
		editorFigures.forEach((figure) => {
			if (figure.value !== value) {
				figure.selected = false;
			} else {
				figure.selected = !figure.selected;
			}
		});

		//it concatenates the puntillo to be able to call updatecurrentfigure with the puntillo attribute on it
		if (isPuntilloSelected) {
			value = value.concat('Puntillo');
		}
		currentValue = value;
		updateCurrentFigure(value);

		//it checks whether it is actually listening or it must be called to the function
		const currentlyListening = get(currentlyListeningStore);

		if (currentlyListening == false) {
			const frequencyEnabled = get(frequencyEnabledStore);
			if (frequencyEnabled == true) {
				frequencyStart();
			} else {
				startSpeechRecognizer();
				console.log("entré speech recognizer")
				let hiddenButtonSpeech = document.getElementById('hiddenButtonSpeech');
				hiddenButtonSpeech.style.display = 'block';
			}

			currentlyListeningStore.update(() => {
				return true;
			});
		}
	};


	 const quantize = async (val) =>{
		openNav();
		hiddenButton.style.display = 'none';
		hiddenButton2.style.display = 'none';
		//CLEAN CANVAS AND ALL realTimeArrayStore
		cleanCanvasAndData();

		//PRINTQUANTIZED
		await printSpeech(val);
		closeNav(); 
	}

	const uploadCurrentSheet = async () => {
		open();
	};

	const downloadSheet = () => {
		let sheet = get(downloadedSheetStore);
		console.log('this is the sheet');
		console.log(sheet);
		if (typeof sheet !== 'undefined') {
			for (let i = 0; i < sheet.length; i++) {
				if (sheet[i].duration == 16) {
					updateCurrentFigure('Semicorchea');
				} else if (sheet[i].duration == 8) {
					updateCurrentFigure('Corchea');
				} else if (sheet[i].duration == 4) {
					updateCurrentFigure('Negra');
				} else if (sheet[i].duration == 2) {
					updateCurrentFigure('Blanca');
				} else if (sheet[i].duration == 1) {
					updateCurrentFigure('Redonda');
				}

				let result = parseKeys(sheet[i].note[0]);
				// TODO (Change this to use new model)
				if (result[1] !== null) {
					addNoteWithOctave(result[0] + result[1], result[2], Alteration.NATURAL);
				} else {
					addNoteWithOctave(result[0], result[2], Alteration.NATURAL);
				}
			}
		}
	};


	function handleSave() {
		openModal(SaveSheet, { title: "Alert", message: "This is an alert" })
	}

	function startLearningRecognition(){
		//it checks whether it is actually listening or it must be called to the function
		const currentlyListening = get(currentlyListeningStore);

		if (currentlyListening == false) {
			const frequencyEnabled = get(frequencyEnabledStore);
			if (frequencyEnabled == true) {
				frequencyStart();
			} else {
				startSpeechRecognizer();
				console.log("entré speech recognizer")
				let hiddenButtonSpeech = document.getElementById('hiddenButtonSpeech');
				hiddenButtonSpeech.style.display = 'block';
				//let recordingButton = document.getElementById('recordingButton');
				//recordingButton.style.display = 'none';
			}

			currentlyListeningStore.update(() => {
				return true;
			});
		}
	}

	function stopLearningRecognition(){
		console.log("stopped recognition")
		let recordingButton = document.getElementById('recordingButton');
		recordingButton.style.display = 'block';
		let hiddenButtonSpeech = document.getElementById('hiddenButtonSpeech');
		hiddenButtonSpeech.style.display = 'none';
		currentlyListeningStore.update(() => {
				return false;
			});
	}
		
	function startRecognition() {
		const recordingButton = document.getElementById('recordingButton');
		const recordingDot = document.getElementById('recordingDot');
		hiddenButton.style.display = 'block';
		hiddenButton2.style.display = 'block';
		recordingButton.style.display = 'none';
		recordingDot.style.display = 'none';
		
		startFrequencyRecognizerRealTime();
	}

		//AI OVERLAY
	//const openNav  = () => {
	//const overlay = document.getElementById('overlay');
	//overlay.style.display = 'block';
	//}
	function uploadCorrenctionNoreWand(){
		hiddenButton3.style.display = 'none';
		storeHumanSheetLSTM() 
		uploadSheetToVAEDataset()
	}
	


	const openNav = () => {
            const overlay = document.getElementById('overlay');

            // Clear the overlay content before adding the GIF
            overlay.innerHTML = '';

            // Create an img element for the GIF
            const gifImage = document.createElement('img');
            gifImage.src = '/assets/gifs/nore.gif'; // Replace 'your.gif' with the actual path to your GIF file
            gifImage.alt = 'Overlay GIF';

			  // Add a class to the img element for styling
			  gifImage.style.maxWidth = '50%'; // Set the width to 50% of the container
				gifImage.style.maxHeight = '50%'; // Set the height to 50% of the container
				gifImage.style.position = 'absolute'; // Position the image absolutely
				gifImage.style.top = '50%'; // Move the top of the image to the vertical center
				gifImage.style.left = '50%'; // Move the left of the image to the horizontal center
				gifImage.style.transform = 'translate(-50%, -50%)'; // Center the image both vertically and horizontally


            // Append the img element to the overlay
            overlay.appendChild(gifImage);

            // Display the overlay
            overlay.style.display = 'block';
        };



	const closeNav = () => {
	const overlay = document.getElementById('overlay');
	overlay.style.display = 'none';
	}

	const stopRecognitionSpeech = () => {
		stopSpeechRecognizer();
		let hiddenButtonSpeech = document.getElementById('hiddenButtonSpeech');
		hiddenButtonSpeech.style.display = 'none';
		currentlyListeningStore.update(() => {
				return false;
			});
	}

	async function stopRecognition(){
		openNav();
		const recordingButton = document.getElementById('recordingButton');
		const recordingDot = document.getElementById('recordingDot');
		hiddenButton.style.display = 'none';
		hiddenButton2.style.display = 'none';
		recordingButton.style.display = 'flex';
		recordingDot.style.display = 'flex';
		
		await stopFrequencyRecognizerRealTimeForDebugging();
		
		//setTimeout(function() {   }, 2000);
		closeNav(); 
		showAndQuantize();

	}




	const editorFigures = [
		{
			icon: redonda,
			value: "Redonda",
			selected: false
		},
		{
			icon: blanca,
			value: "Blanca",
			selected: false
		},
		{
			icon: negra,
			value: "Negra",
			selected: false
		},
		{
			icon: corchea,
			value: "Corchea",
			selected: false
		},
		{
			icon: semicorchea,
			value: "Semicorchea",
			selected: false
		}
	];
</script>

<div class="min-w-screen min-h-screen bg-gradient-1 relative flex flex-col">
	<div class="absolute top-4 left-4 p-4">
        <BackArrow path='/' />
    </div>
	<div class="quantizationButtonGroup" style="--theme-color: {color}">
		<button class="generalButton" on:click={() => quantize(64)} >Blanca</button>
		<button class="generalButton" on:click={() => quantize(32)} >Negra</button>
		<button class="generalButton" on:click={() => quantize(16)} >Corchea</button>
		<button class="generalButton" on:click={() => quantize(8)} >Semicorchea</button>
		<button class="generalButton" on:click={() => quantize(4)} >Fusa</button>
		<button class="generalButton" on:click={hideAndQuantize} >Confirm</button>
	</div>
	<Toast/>
	
	<div
		class="max-w-4xl mx-auto w-full px-3 flex flex-1 items-center justify-center py-5 gap-5"
	>
		<!-- Left side -->
		<div class="flex justify-center items-stretch space-x-6 w-full">
			<!--<div class="flex flex-wrap space-y-2 w-24 shrink-0">
				{#each editorFigures as editorButton}
					<EditorButton
						action={() => updateCurrentFigureAndListen(editorButton.value, isPuntilloSelected)}
						imagePath={editorButton.icon}
					></EditorButton>
				{/each}
			</div>-->
			<div class="bg-white rounded-md flex-1 p-4 sm:max-w-md md:max-w-xl xl:max-w-3xl 2xl:max-w-5xl">
				<div class="sheetDisplayer">
					<div id="scrollable-container" class="overflow-auto">
						<div class="flex justify-center items-center">
							<div class="w-full max-w-screen-md">
								<div class="bg-white p-4 shadow-md rounded-md">
									<div id="vf" class="vf-container"></div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="inset-x-0  bottom-0 p-4">
					<div class="flex justify-between ml-auto">
						<button on:click={playAllMusic}
										class="outline-none bg-dark-600 rounded-full text-white flex justify-center items-center w-12 h-12 -translate-x-1/2 shrink-0"
						>
							<SVGImage imagePath={playButton} fillColor="white" />
						</button>
						
						<button on:click={startLearningRecognition} id="recordingButton" style="display: flex; justify-content: center; align-items: center;" class="w-10 h-10 rounded-full bg-red">
							<span id="recordingDot" class="w-3 h-3" style="background-color: white; border-radius: 50%;"></span>
						</button>

						<button on:click={stopLearningRecognition} id="hiddenButton" style="display: none;" type="button" class="w-10 h-10 rounded-full bg-red flex justify-center items-center">
							<div class="relative">
								<span id="hiddenButton2" class="w-3 h-3 bg-white absolute inset-0 m-auto"></span>
							</div>
						</button>

						<button on:click={uploadCorrenctionNoreWand} style="display: none;" id="hiddenButton3" class="w-10 h-10 rounded-full bg-green-500 flex justify-center items-center">
							<div style="margin-left: 7px;">
							<SVGImage imagePath={send6} id="hiddenButton3" fillColor="transparent" style="height: 80%; width: 80%; display: none;"/>
						</div>
						</button>

						

						<button on:click={stopRecognitionSpeech} id="hiddenButtonSpeech" style="display: none;" type="button" class="w-10 h-10 rounded-full bg-red flex justify-center items-center">
							<div class="relative">
								<span id="hiddenButtonSpeech" class="w-3 h-3 bg-white absolute inset-0 m-auto"></span>
							</div>
						</button>
						
						<div id="overlay"></div>
						<!--<button class="generalButton" on:click={printSheetOfMusic} >P.S.</button>-->
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<style>
	 #overlay {
      position: fixed;
      display: none;
	  justify-content: center;
   	  align-items: center;	
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 200000;
      background: rgba(0, 0, 0, 0.78);
    }

	
	.sheetDisplayer {
		grid-column: 3 / 18;
		grid-row: 1;
	}

    .quantizationButtonGroup {
        position: fixed;
        bottom: 0;
    	left: 50%;
		transform: translateX(-50%);
        background-color: #ffffff; /* Optional: Set a background color for the fixed area */
        z-index: 1000; /* Optional: Set a higher z-index to ensure it appears above other elements */
    }

	.quantizationButtonGroup{
		position: fixed;
		grid-column: 2 / 20;
		grid-row: 1;
		visibility: var(--theme-color);
		z-index: 1000; 
		background-color: #2c2c2c;
	}



	.generalButton {
		background-color: #555555;
		padding: 10px 20px;
		border: none;
		color: rgb(255, 255, 255);
		text-align: center;
		text-decoration: none;
		display: inline-block;
		font-size: 12px;
		margin: 4px 2px;
		cursor: pointer;
	}
</style>

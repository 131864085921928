<script lang="ts">
	import { notifications } from './Notifications';
	export let themes = {
		red: 'background: rgba(231, 15, 15, 0.6); border: 1px solid rgba(60, 255, 61, 0.3)',
		green: 'background: rgba(62, 229, 0, 0.6); border: 1px solid rgba(62, 229, 0, 0.3)',
		yellow: '#f0ad4e',
		blue: '#5bc0de',
		grey: 'background: rgba(0, 0, 0, 0.5); border: 1px solid rgba(0, 0, 0, 0.3)',
	};
</script>

<div class="notifications">
	{#each $notifications as notification (notification.id)}
		<div class="toast" >
			<div class="content" style="{themes[notification.type]};">{notification.message}</div>
			{#if notification.icon}<i class={notification.icon} />{/if}
		</div>
	{/each}
</div>

<!--<div class="notifications">
    {#each $notifications as notification (notification.id)}
        <div
            animate:flip
            class="toast"
            style="background: {themes[notification.type]};"
            transition:fly={{ y: 30 }}
        >
            <div class="content">{notification.message}</div>
            {#if notification.icon}<i class={notification.icon} />{/if}
        </div>
    {/each}
</div>-->
<style>
    .notifications {
        position: fixed;
        top: 10px;
        left: 0;
        right: 0;
        margin: 0 auto;
        padding: 0;
        z-index: 10000; /* Increase the z-index value */
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        pointer-events: none;
        margin-left: auto;
        margin-right: auto;
    }

    .toast {
        flex: 0 0 auto;
        margin-bottom: 10px;
        z-index: 10000; /* Increase the z-index value for individual toasts */
    }

    .content {
        padding: 10px;
        display: block;
        color: white;
        font-weight: 500;

        /* Glassmorphism styles */
        background: rgba(255, 255, 255, 0.2);
        border-radius: 16px;
        box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        border: 1px solid rgba(255, 255, 255, 0.3);
    }
</style>


<script>
    import arrowLeft from "../assets/icons/arrow-left.svg";
    import SVGImage from "../components/SVGImage.svelte";
    import { replace } from "svelte-spa-router";
    import TempoTapButton from "../components/TempoTapButton.svelte";
    import { bpmStore } from "../store";
    import { notifications } from "../toast/Notifications";
    import Toast from "../toast/Toast.svelte";

    let bpm = 0;
    function goBack() {
        replace('/');
    }

    function goToTempoSelection() {
        if (bpm <= 0) {
            // TODO (Display error message)
            notifications.red('BPM\'s can\'t be 0', 1500);
            return;
        }
        bpmStore.set(bpm);
        replace('/tempo-selection');
    }
</script>

<div class="min-w-screen min-h-screen bg-gradient-1 relative flex flex-col">
    <Toast/>
    <div class="max-w-sm mx-auto w-full px-3 flex flex-1 flex-col py-5 gap-5">
        <div class="flex items-center justify-center relative">
            <!-- on:click={() => show = false} -->
            <button
                  on:click={goBack}
                    type="button"
                    class="outline-none cursor-pointer absolute left-0 -top-1.5 p-1"
            ><SVGImage imagePath={arrowLeft} class="text-white w-5" />
            </button>
            <div class="text-center space-y-4">
                <h1 class="text-xl font-semibold text-white-100 leading-none">
                    Record your idea
                </h1>
                <p class="font-semibold leading-sung text-white-400 text-center ">
                    Tap the record button at rhythm of your musical idea so that aria
                    capture the pulsation of your melody. (Recommended more than 4 taps)
                </p>
            </div>
        </div>

        <!-- middle section -->
        <div class="space-y-6 flex justify-center flex-1">
            <div class="relative flex flex-wrap justify-center items-center">
                <TempoTapButton bind:bpm={bpm}/>
                <span class="text-2xl font-medium text-white leading-none text-center block"
                >BPM: {bpm}</span>
            </div>
        </div>

        <!-- Bottom section here -->
        <div class="relative ">
            <button
              on:click={goToTempoSelection}
                    class=" bg-white-400 w-full p-4 rounded text-center text-lg leading-none font-semibold cursor-pointer text-dark-400 duration-300 ease-in-out hover:bg-white"
            >Accept Rhythm</button
            >
        </div>
    </div>
</div>

<script>
    import { onMount } from 'svelte';
    import { replace, link } from 'svelte-spa-router';
    import SVGImage from "../components/SVGImage.svelte";
    import playButton from "../assets/icons/playButton.svg";
  
    onMount(() => {
      setVideoEndListener();
    });
  
    function setVideoEndListener() {
      const video = document.getElementById('landing-video');
      const playButton = document.getElementById('playButton');
      
      video.addEventListener('ended', () => {
        setTimeout(() => {
          replace('/demo');
        }, 1000);
      });
  
      // Show custom play button when video is paused
      video.addEventListener('pause', () => {
        playButton.style.display = 'block';
      });
  
      // Hide custom play button when video is playing
      video.addEventListener('play', () => {
        playButton.style.display = 'none';
      });
  
      // Toggle play/pause on button click
      playButton.addEventListener('click', () => {
        if (video.paused) {
          video.play();
        } else {
          video.pause();
        }
      });
    }
  </script>
  


<!-- Landing page with a title a subtitle and a video-->
<div id="canvas" class="min-w-screen min-h-screen bg-gradient-1 relative flex flex-col justify-center items-center">
    <!-- Multiline text align to center -->
    <h1 class="mb-4 text-4xl font-extrabold leading-none text-center tracking-tight text-gradient md:text-5xl lg:text-6xl animate-fade-in m-4">
      Conviértete en el músico que siempre has querido
    </h1>
    <h3 class="mb-6 text-xl font-normal text-center text-gray-500 lg:text-2xl sm:px-16 xl:px-48 dark:text-gray-400 animate-fade-in">
      Dale al play para empezar a jugar
    </h3>
    
    <div class="video-wrapper">
      <video id="landing-video" class="w-full sm:w-3/4 md:w-1/2 lg:w-1/3 xl:w-1/4" controls>
        <source src="../assets/video/intro.mp4" type="video/mp4">
        Your browser does not support the video tag.
      </video>
      <button id="playButton" class="play-button">
        <SVGImage imagePath={playButton} fillColor="white" />
      </button>
    </div>
    
    <a href="/login" use:link class="mt-6 px-6 py-3 text-lg font-medium text-white">Ya tengo una cuenta</a>
  </div>
  

<style>
    @keyframes animatedTextGradient {
        to {
        background-position: 200% center;
        }
    }

    header {
            text-align: center;
            padding: 50px 0;
            font-size: 2.5em;
            opacity: 0;
            transform: translateY(20px);
            transition: opacity 0.5s ease-out, transform 0.5s ease-out;
        }
    header.visible {
        opacity: 1;
        transform: translateY(0);
    }

    .text-gradient {
        background: radial-gradient(
        circle at 100%,
        #ffffff,
        #cccccc 50%,
        #e0e0e0 75%,
        #b3b3b3 75%
        );
        font-weight: 600;
        background-size: 200% auto;
        color: #000;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        animation: animatedTextGradient 1.5s linear infinite;
    }

    .video-wrapper {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%; /* Ensure it takes the full width of the parent container */
        max-width: 80%; /* Set a larger maximum width */
        height: 60vh; /* Set a larger height, 60% of the viewport height */
        margin: 0 auto; /* Center it horizontally */
        }

        video {
        width: 100%; /* Make the video take the full width of the .video-wrapper */
        height: 100%; /* Make the video take the full height of the .video-wrapper */
        object-fit: cover; /* Ensure the video covers the container */
    }

    .play-button {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        background: rgba(0, 0, 0, 0.5);
        border: none;
        color: white;
        font-size: 24px;
        padding: 10px 20px;
        cursor: pointer;
    }

    /* Show the play button when the video is paused */
    video.paused + .play-button {
        display: block;
    }
</style>

import * as Meyda from "meyda";
import * as load from "audio-loader";
import * as wav from "node-wav";
import KNN from "ml-knn";
import {uploadSheetToVAEDataset, storeOriginalSheetLSTM, storeAISheetLSTM} from '../Editor.ts';  //++//++ uploadSheetToVAEDataset
import { lstmNore, doremi, timeSignatureStore, realTimeArrayStore, bpmStore} from '../store'; //++//++ heardRealTimeArrayStore, correctedRealTimeArrayStore
import {get} from 'svelte/store';
import * as tf from "@tensorflow/tfjs";
import { PitchDetector } from "pitchy";
import 'browser-hrtime';
import {notifications} from '../toast/Notifications'
import { modifyOctave, updateCurrentAccidental, updateCurrentFigure } from "../modules/storeManager.js";
import { addNote, addNoteWithOctave } from "../modules/editNoteManager.js";
import { cleanCanvasAndData } from "../modules/sheetManager.js";
//++//++ import { abs } from "@tensorflow/tfjs";
//++//++ import {staveToSampleParserTest} from "./vaeExtractor.js";

//import App from './App.svelte';
// App.elnombredelafuncion
//

/* THERE ARE THREE DIFFERENT MODES:

1-DICTATION MODE: YOU HAVE TO STOP BETWEEN DICTATIONS/HUMMINGS LO LET THE PROGRAM THINK TO
2-SPEED DICTATION MODE: YOU CAN DICTATE/HUM THINGS IN ANY WANTED VELOCITY
3-REAL TIME MODE: GIVEN A TEMPO, CATCHES EVEN RITHM 

*/

//SETUP OF REAL TIME RECOGNITION
//BPM DE NEGRA

let bpm=get(bpmStore)


//duración de una negra en segundos. 0,75 sería que una negra dura 0,75 segundos.
let quaverDuration=60/bpm
//duración de una negra en milisegundos. 0,75 sería que una negra dura 
let quaverDurationMs=quaverDuration*1000

let demiSemiQuaverDurationMs=quaverDurationMs/8

//Array obtenido de reconocimiento a tiempo real
//let realTimeArray=[]
let realTimeArray = get(realTimeArrayStore);



//var hrstart = process.hrtime()
//var hrend = process.hrtime(hrstart)
let isRecording=false;//this variable controls the first empty beats for getting the musician used to rythm
let sampleCount=0
let beatNum=1
var FEATURE_NAME_RMS = 'rms'
var THRESHOLD_RMS_FREQUENCY = 0.002 //ESTABA EN 0.02 en el modo optimo, acabo de cambiar porque estoy en caafeteria
var cur_rms = 0
var frequencyStacked=[]
let currentFrequency;
let currentClarity;
let CLARITY_THRESHOLD=85;//80 es en el demo que mejor funciona
let analyzer;
let audioContext;
var hrstart;
var hrend;
let timer;
let curRMSAboveThreshold=false;
let beat=0;

//This basically sais that if there is a silece you should extend the note sung
let PAD_SILENCE=false;
//MODE THRESHOLD THAT IS USED TO STATE IF THE AMOUNT OF 0 VALUES IS SMALLER THAN THE MODE_0_THRESHOLD
//PERCENTAGE IT MEANS TO SUBSITUTE WITH MOST HEARD NOTE EXCEPT FROM 0 
let MODE_0_THRESHOLD=0.3;

//PARSING OF MAX FREQUENCY OR MINIMUM. 
let MINIMUM_FREQ_THRESHOLD=50;
let MAXIMUM_FREQ_THRESH=2000;
//THIS SAYS WHEN THE PREVIOUS HAPPESN WHETHER YOU WANT TO SUBSITUTE BY 0 (value is 0) OR WITH CONCATENATING PREVIOUS VALUE (value should be set to -1)
let FREQ_THRESHOLD_PARSING=-1;

let samplePerBeat=0

//////////////////////////////// CODE FOR LSTM //////////////////////////////// 
//80%acc

let wordToIndexJson = '{"698.46": 0, "277.18": 1, "7458.62": 2, "0": 3, "880": 4, "329.63": 5, "61.74": 6, "1661.22": 7, "103.83": 8, "146.83": 9, "523.25": 10, "1760": 11, "392": 12, "1567.98": 13, "220": 14, "185": 15, "17.32": 16, "46.25": 17, "138.59": 18, "1479.98": 19, "34.65": 20, "23.12": 21, "233.08": 22, "69.3": 23, "932.33": 24, "18.35": 25, "30.87": 26, "123.47": 27, "554.37": 28, "77.78": 29, "207.65": 30, "32.7": 31, "1318.51": 32, "174.61": 33, "82.41": 34, "493.88": 35, "92.5": 36, "38.89": 37, "783.99": 38, "1174.66": 39, "293.66": 40, "659.25": 41, "155.56": 42, "43.65": 43, "1396.91": 44, "21.83": 45, "587.33": 46, "622.25": 47, "65.41": 48, "98": 49, "830.61": 50, "73.42": 51, "440": 52, "24.5": 53, "27.5": 54, "51.91": 55, "246.94": 56, "58.27": 57, "7040": 58, "311.13": 59, "55": 60, "196": 61, "261.63": 62, "116.54": 63, "466.16": 64, "349.23": 65, "369.99": 66, "49": 67, "1244.51": 68, "164.81": 69, "36.71": 70, "1108.73": 71, "41.2": 72, "110": 73, "20.6": 74, "415.3": 75, "987.77": 76, "87.31": 77, "739.99": 78, "130.81": 79, "25.96": 80, "1046.5": 81, "29.14": 82}'
let indexToWordJson = '{"0": "698.46", "1": "277.18", "2": "7458.62", "3": "0", "4": "880", "5": "329.63", "6": "61.74", "7": "1661.22", "8": "103.83", "9": "146.83", "10": "523.25", "11": "1760", "12": "392", "13": "1567.98", "14": "220", "15": "185", "16": "17.32", "17": "46.25", "18": "138.59", "19": "1479.98", "20": "34.65", "21": "23.12", "22": "233.08", "23": "69.3", "24": "932.33", "25": "18.35", "26": "30.87", "27": "123.47", "28": "554.37", "29": "77.78", "30": "207.65", "31": "32.7", "32": "1318.51", "33": "174.61", "34": "82.41", "35": "493.88", "36": "92.5", "37": "38.89", "38": "783.99", "39": "1174.66", "40": "293.66", "41": "659.25", "42": "155.56", "43": "43.65", "44": "1396.91", "45": "21.83", "46": "587.33", "47": "622.25", "48": "65.41", "49": "98", "50": "830.61", "51": "73.42", "52": "440", "53": "24.5", "54": "27.5", "55": "51.91", "56": "246.94", "57": "58.27", "58": "7040", "59": "311.13", "60": "55", "61": "196", "62": "261.63", "63": "116.54", "64": "466.16", "65": "349.23", "66": "369.99", "67": "49", "68": "1244.51", "69": "164.81", "70": "36.71", "71": "1108.73", "72": "41.2", "73": "110", "74": "20.6", "75": "415.3", "76": "987.77", "77": "87.31", "78": "739.99", "79": "130.81", "80": "25.96", "81": "1046.5", "82": "29.14"}'
// Parse the JSON strings to JavaScript objects
let wordToIndex = JSON.parse(wordToIndexJson);
let indexToWord = JSON.parse(indexToWordJson);
//////////////////////////////// CODE FOR LSTM //////////////////////////////// 

function sleep(ms) {
    return new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  }


/*export async function startFrequencyRecognizerRealTime() {
    hrstart = process.hrtime()
    notifications.grey("4", quaverDurationMs);
    await sleep(quaverDurationMs);
    notifications.grey("3", quaverDurationMs);
    await sleep(quaverDurationMs);
    notifications.grey("2", quaverDurationMs);
    await sleep(quaverDurationMs);
    notifications.grey("1", quaverDurationMs);
    await sleep(quaverDurationMs);
    console.log(hrstart);
}*/



//this is used to setup the actual beat (different for /4 or /8)
//1-get the bpm tapped by user
    /*
    As an idea of what bpm should represent
    2/4, 3/4, 4/4,  <- si el denominador es 4 tomas el numerador
    3/8, 6/8, 9/8, 12/8 <- si el denominador es 8 divides el numerador entre 3
    */
function loadBeat(){
    //beat number in a measure
    //this for /4 tempos
    let beat = get(timeSignatureStore).numerator;
    //this for /8 tempos
    if (get(timeSignatureStore).denominator==8){
        beat=beat/3
    }
    return beat
}

function setCountLoop(){
    //setting up if we are having 32 samples per beat (/4 tempos, black) or 48 (/8 tempos, black with dot)
    if(get(timeSignatureStore).denominator==4){
        samplePerBeat=32
    }
    else if(get(timeSignatureStore).denominator==8){
        samplePerBeat=48
    }
}


export async function startFrequencyRecognizerRealTime() {
    realTimeArray=[]
    
    frequencyStacked=[]
    isRecording=false
    sampleCount=0
    beatNum=1
    //1-get the bpm tapped by user
    /*
    As an idea of what bpm should represent
    2/4, 3/4, 4/4,  <- si el denominador es 4 tomas el numerador
    3/8, 6/8, 9/8, 12/8 <- si el denominador es 8 divides el numerador entre 3
    */
    bpm=get(bpmStore)

    //2-you get the beat number of a measure - WARNING: if you have a 3/4 or a 3/8 it will take 3 either ways
    //this means that the current system will treat each beat as a quaver in 3/4 and as a semiquaver in 3/8
    //in the future this should not happen, should be 1 beat divided in two for /4 and 1 beat in 3 for /8
    beat = loadBeat()

    //setting up if we are having 32 samples per beat (/4 tempos, black) or 48 (/8 tempos, black with dot)
    setCountLoop()

    //3-Quaver duration in seconds. 0,75 sería que una negra dura 0,75 segundos.
    quaverDuration=60/bpm

    //4-Now a quaver in miliseconds. 
    quaverDurationMs=quaverDuration*1000

    //5-Finally demisemiquaver duration
    demiSemiQuaverDurationMs=quaverDurationMs/8


    //(opening audiocontext)
    audioContext = new window.AudioContext();
    const analyserNode = audioContext.createAnalyser();
    audioContext.resume();

    /*
    beat = get(timeSignatureStore).numerator;
    console.log("beat")
    console.log(beat)

    
    for(let i=beat; i!=0; i--){
        notifications.grey(i.toString(), quaverDurationMs);
        if(i==beat){
            navigator.vibrate(200);
        }
        else{
            navigator.vibrate(50);
        }
        await sleep(quaverDurationMs);
    }*/
  

    //(call to PitchAndRMSgetter that basically its a recursive function to get the pitch at 
    //every demisemiquaver/4 duration. Four samples per demisemiquaver (fusa in spanish).
    //fusa - 4 samples
    //fusa con puntillo - 6 samples
    //semicorchea - 8 samples
    //semicorchea con puntillo - 12 samples
    //corchea - 16 samples
    //corchea con puntillo - 24 samples
    //negra - 32 samples
    //negra con puntillo - 48 samples
    //blanca - 64 samples
    //blanca con puntillo - 96 samples
    //redonda - 128 samples
    //
    //
    //Establecer duraciones faltantes posibles para tener en cuenta
    //)
    navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
      audioContext.createMediaStreamSource(stream).connect(analyserNode);
      const detector = PitchDetector.forFloat32Array(analyserNode.fftSize);
      const input = new Float32Array(detector.inputLength);
      PitchAndRMSgetter(analyserNode, detector, input, audioContext.sampleRate);
    });

    

// (call meyda - it gives back all the heard frequencies above the threshold)
onMicDataCall([FEATURE_NAME_RMS], dictateFrequency) 
.then((meydaAnalyzer) => {
    meydaAnalyzer.start()
    
}).catch((err)=>{
    alert(err)
})

}

/*function getFrequencyAndClarity(analyserNode, detector, input, sampleRate){
    analyserNode.getFloatTimeDomainData(input);
    const [pitch, clarity] = detector.findPitch(input, sampleRate);
    let currentFrequency=Math.round(pitch * 10) / 10
    let currentClarity=Math.round(clarity * 100)
    return [analyserNode, detector, input, sampleRate, currentFrequency, currentClarity]
}*/

function vibrateAndNotify(sampleAndBeat){
    //1-sampleCount is the current sample we are in the beat
    //
    //sampleCount begins at 0 and it will have to traverse all the samples for a beat. 
    //when sampleCount goes back to 0 it means its the beginning of a new beat. 
    //every sample traversed adds sampleCount++, in our case when it reaches 32 or 48 it goes back to 0 since its 
    //the end of a beat.
    //
    //At the same time beatNum does beatNum++ when the 32 samples are reached, this way stating its 
    //the beginning of a new beat. And since we have the limit of beats stated by our tempo if it reaches
    //the number, it goes back to 0.
    //
    //In the future if complex rythms must be added, countLoop could be an array with the vibration times
    //this would mean, one quaver vibration and one black vibration could be countLoop [16,32]
    [sampleCount, beatNum] = sampleAndBeat;

    if(sampleCount==1){
        if(beatNum==1){
            navigator.vibrate(200);
        }
        else{
            navigator.vibrate(50);
        }
        isRecording ? notifications.green(beatNum.toString(), quaverDurationMs) : notifications.grey("get ready... "+beatNum.toString(), quaverDurationMs) 
      }
      if(sampleCount==samplePerBeat){//32 for black per beat, 48 for black with dot per beat
        sampleCount=0
        //it has reached the limit of beats so it is set to 1 again
        if(beatNum==beat){
            isRecording=true
            beatNum=1;
        }
        else{
            beatNum++;
        }
      }
      return [sampleCount, beatNum]
}

function concatenateHeardPitchArray(currentClarity, currentFrequency){
      //TODO: not sure if it does what is supposed to do
      //3-If the sound is above RMS value, then you concatenate it if not, you copy the last value
      //IT ACTUALLY SHOULD ADD 0 SO THAT YOU CHANGE IT IF YOU WISH AT ARRAY TREATMENT PART
      //console.log(curRMSAboveThreshold)
    if(isRecording==true){
        if(curRMSAboveThreshold==true && currentClarity>CLARITY_THRESHOLD){
            console.log("Clear Frequencies: "+currentFrequency);
            let heardFreq=frequencyParserToRealFreq(currentFrequency)
            //IF THRESHOLD IS OUT OF BOUNDARIES PARSE WITH 0 OR PREVIOUS NOTE. if value is 0 it means to add 0 if value is other itemans concat last note
            if(heardFreq<MINIMUM_FREQ_THRESHOLD || heardFreq>MAXIMUM_FREQ_THRESH){
                if(FREQ_THRESHOLD_PARSING==0){
                    heardFreq=0
                    realTimeArray=realTimeArray.concat(heardFreq);
                }
                else{
                    realTimeArray=realTimeArray.concat(realTimeArray[realTimeArray.length-1]);
                }
                
            }
            else{
                realTimeArray=realTimeArray.concat(heardFreq);
            }
        }
        else{
                if(PAD_SILENCE==true){
                    if(realTimeArray[realTimeArray.length-1]!=undefined){
                        console.log("Concatenating previous value: "+realTimeArray[realTimeArray.length-1]);
                        realTimeArray=realTimeArray.concat(realTimeArray[realTimeArray.length-1]);
                    }
                    else{
                        console.log("Concatenating zero value: 0");
                        realTimeArray=realTimeArray.concat(0);
                    }
                }
                else{
                    console.log("Concatenating zero value: 0");
                    realTimeArray=realTimeArray.concat(0);
                }
                
            }
    }

}

//TODO: AQUI ESTA LA MIGA A COMPROBAR SI LO HACE CORRECTAMENTE
//AQUI SE AÑADE TODO A UN ARRAY
function PitchAndRMSgetter(analyserNode, detector, input, sampleRate) {
    
    //1-setting the vibration and notification of beat. It takes the beat number and current sample count.
    sampleCount++;
    //console.log(sampleCount)
    [sampleCount, beatNum]=vibrateAndNotify([sampleCount, beatNum])
        

    //2-you get the pitch and clarity values
    analyserNode.getFloatTimeDomainData(input);
    const [pitch, clarity] = detector.findPitch(input, sampleRate);
    let currentFrequency=Math.round(pitch * 10) / 10
    let currentClarity=Math.round(clarity * 100)

    //concatenate what has been heard
    concatenateHeardPitchArray(currentClarity, currentFrequency)  

    timer = window.setTimeout(() => PitchAndRMSgetter(analyserNode, detector, input, sampleRate),demiSemiQuaverDurationMs/4);

      //PitchAndRMSgetter(analyserNode, detector, input, sampleRate);
  }

  //TODO: export function fillLastMeasure(){}
  //add silences as 0 to the end of measure
  //TODO: export function fillFirstMeasure(){}
  //add silences at the begining of measure
  function fillFistMeasure(realTimeArray){
    //añdir pasos
    return realTimeArray
  }


  function fillLastMeasure(realTimeArray){
    //paso 1: calculas cuantos samples debe haber en tu compas
    //paso 2: calculas el modulo (para ello recorres el array saltando de compas en compas recordando el
    //index del sample al que has saltado cuando te pases porque has acabado. restas la muestra del ultimo
    // compas con la duracion)
    //paso 3: añades esa cantidad de ceros, o tomas la ultima muestra y añades esa cantidad en valor de frecuencia


    let amount=realTimeArray.length;
    let measureSize=beat*samplePerBeat;
    while(amount>=0){
        amount=amount-measureSize;
    }

    let samplesToAdd=Math.abs(amount);
    if (samplesToAdd!=0){
        for(let i=0; i<samplesToAdd; i++){
            realTimeArray=realTimeArray.concat(0);
        }
    }

    return realTimeArray;
  }

  function cleanRealTimeArrayPadding(){
    realTimeArray=fillFistMeasure(realTimeArray);
    realTimeArray=fillLastMeasure(realTimeArray);
  }

  export function stopFrequencyRecognizerRealTime() {
    notifications.green('Stopped Frequency Recognition...', 1500);
    analyzer.stop()
    audioContext.close()
    console.log("realTimeArray RAW VERSION: ")
    console.log(realTimeArray)
    window.clearTimeout(timer);
    

    realTimeArray=fillLastMeasure(realTimeArray);
    
    //En nuestro caso tenemos 4 muestras por fusa, para cuantizar el valor corrrespondiente sería: fusa 4, semicorchea 8, corchea 16, negra 32
    //(ahora olvidate de esto) elegir tipo de cuantización (fusa 1, semicorchea 2, corchea 4, negra 8) implica que coges de uno en un elemento del array de dos en dos...
    
    //This is optional preprocessing of an array to delete n first elements (recorder is desyncronized usually
    //this gives the option to syncronize)
    let n = 10;
    realTimeArray.splice(0, n);
    console.log(realTimeArray);

    realTimeArrayStore.update(() => {
        return realTimeArray;
    });


    printSpeech(16, true);
}

//TODO: export function heardArrayTreatment(){}
//The idea here is to think on different ways to treat the array 
//1-select if you want the 0s on the array to be 0s or an extension of previous note
//2-select if the first and last measures you want 0 padding or note padding
//think on the most effective way of quantizing so that it makes sense what was sang
//4-even apply here AI algorithms to smoothen the vector

//TODO: change name of print speech to: export function quantizeArray(){}
//instead of confusing with numbers make it so that you can add quaver, semiquaver... as input
//be careful here cause there are figures that if there is not representation, you are fucked up!!!!
//and change code in a smooth way

function arrayTreatment(){
    //silences (0) or extension of note?
    //0 padding on the beginning and end?
    //smoothen array with AI
    //Think here the best postprocessing
}

function printPerfectNote(result, currentNoteLength){
    let noteLengths = [4,6,8,12,16,24,32,48,64,96,128]

    //This function checks whether the currentNoteLength is a perfect note or not, if its not it will do the treatment of it going to the else
    //otherwise it will directly print it
    if (noteLengths.includes(currentNoteLength)) {
        console.log("The value is in the array.");
        if(currentNoteLength==4){
            updateCurrentFigure("Fusa")
        }
        else if(currentNoteLength==6){
            updateCurrentFigure("FusaPuntillo")
        }
        else if(currentNoteLength==8){
            updateCurrentFigure("Semicorchea")
        }
        else if(currentNoteLength==12){
            updateCurrentFigure("SemicorcheaPuntillo")
        }
        else if(currentNoteLength==16){
            updateCurrentFigure("Corchea")
        }
        else if(currentNoteLength==24){
            updateCurrentFigure("CorcheaPuntillo")
        }
        else if(currentNoteLength==32){
            updateCurrentFigure("Negra")
        }
        else if(currentNoteLength==48){
            updateCurrentFigure("NegraPuntillo")
        }
        else if(currentNoteLength==64){
            updateCurrentFigure("Blanca")
        }
        else if(currentNoteLength==96){
            updateCurrentFigure("BlancaPuntillo")
        }
        else if(currentNoteLength==128){
            updateCurrentFigure("Redonda")
        }
        // TODO (Is this needed??)
        updateCurrentAccidental(result[1]);
        if (result[1]!=null){
            console.log("NOTE if:")
            console.log(result[0])
            console.log(result[2])
            console.log(result[3])
            console.log(result[1])
            console.log(currentNoteLength)
            addNoteWithOctave(result[0],result[2]+1,result[3],result[1]);
        }
        else{
            //console.log("NOTE else:")
            //console.log(result[0])
            //console.log(result[2])
            //console.log(result[3])
            //console.log(result[1])
            //console.log(currentNoteLength)
            addNoteWithOctave(result[0],result[2]+1,result[3],result[1]);
        }

      } else {
        console.log("The value is not in the array.");
        printUnperfectNote(result, currentNoteLength)
      }
}

function printUnperfectNote(result, currentNoteLength){
    let noteLengths = [4, 6, 8, 12, 16, 24, 32, 48, 64, 96, 128];

    //if it arrived a point where the note is perfect simply print it and exit the recusive function
    if (noteLengths.includes(currentNoteLength)) {
        console.log("The value is in the array.");
        printPerfectNote(result, currentNoteLength)
    } else {
        console.log("The value is not in the array.");
        //if the note is not perfect the first step is to get the closest smaller value to the note and print it
        let previousValue = null;

        for (let i = 0; i < noteLengths.length; i++) {
            if (noteLengths[i] >= currentNoteLength) {
                break;
            }
            previousValue = noteLengths[i];
        }

        //Now print the note with that length and go back to the recursivity
        printPerfectNote(result, previousValue)

        //call again with the remaining length to this function
        let remainingLength=currentNoteLength-previousValue
        console.log("printing remaining note recursively")
        printUnperfectNote(result, remainingLength)
    }
}

function applyNoreWingMagic(realTimeArray, size){
    const length = size;
    let slicedArrays = [];
    let reconstructedArrays=[];
    for (let i = 0; i < realTimeArray.length; i += length) {
        const slicedArray = realTimeArray.slice(i, i + length);
        slicedArrays.push(slicedArray);
    }

    for (let i = 0; i < slicedArrays.length; i++) {
        let reconstructedArr=predictLSTM(slicedArrays[i], size);
        reconstructedArrays=reconstructedArrays.concat(reconstructedArr);
    }
    return reconstructedArrays;
}

export function printSpeech(quantization, applyNoreMagic){
    return new Promise((resolve) => {
        setTimeout(function() {
            
        
    let realTimeArray = get(realTimeArrayStore);
    if (applyNoreMagic==false){
        //Do Nothing
        //let realTimeArray = get(realTimeArrayStore);
    }else{
        let realTimeArraySt = get(realTimeArrayStore);
        realTimeArray = applyNoreWingMagic(realTimeArraySt, 384);
    }

    
    console.log("original array previous to NoreWingMagic")
    //console.log(realTimeArraySt.toString())
    console.log("NoreWingMagic applied")
    //console.log(realTimeArray.toString())
    

    //console.log("beatNum")
    //console.log(beatNum)
    let noteArray=[]
    let finalArray=[]
    let counterForQuantization=1;
    
    //1-basicamente recorre el numero de samples que se le pide y calcula la moda, despues lo añade al finalArray
    //slicesToMode is just used for debuggin how different heard slices are transformed into its corresponding final array
    let slicesToMode = [];
    for(let i=0; i<realTimeArray.length; i++){
         noteArray=noteArray.concat(realTimeArray[i])

        if(counterForQuantization==quantization){
            let mode=statisticMode(noteArray);
            //let mode=statisticModeWithThreshold(noteArray, MODE_0_THRESHOLD);
            
            finalArray=finalArray.concat(mode);
            counterForQuantization=1;
            slicesToMode.push(noteArray)
            noteArray=[] 
            //CALCULO LA MODA Y APPEND A UN ARRAY
        } 
        else{
            counterForQuantization++;
        }
    }
    console.log("slicesToFinalArray: ")
    console.log(slicesToMode) 
    console.log("finalArray: ")
    console.log(finalArray) 
    console.log("vaeArray:")
    //staveToSampleParserTest()
    

    //NOW HAVING THE ARRAY IN THE CURRENT QUANTIZATION, LETS PRINT IT ON SCREEN!!!
    //si en vez de elegir una muestra por fusa son 4: fusa 4, semicorchea 8, corchea 16, negra 32
    //currentNotelength: 1 fusa, 2 semicorchea,4 corchea, 8 negra, 16, blanca, 32 redonda
    let currentNoteLength=quantization

    let parsedArray=[]

    for(let i=0; i<finalArray.length;i++){
        parsedArray=parsedArray.concat(frequencyParser(finalArray[i]))

        if(finalArray[i]==finalArray[i+1]){
            currentNoteLength=currentNoteLength+quantization;
            console.log("note")
            console.log("silence")
            console.log("notelenght")
            console.log(currentNoteLength)
        }
        else{
            
            let result=frequencyParser(finalArray[i]);
            console.log("note")
            console.log(result) //aqui me lo hace bien el bemol y todo: ['si', 'b', 2, false] ESTO ES LO QUE RECIBO
            console.log("notelenght")
            console.log(currentNoteLength)
             
            printPerfectNote(result, currentNoteLength)


            //TODO: mirar esto si cambia al montarme la funcion que me acabo de montar
            currentNoteLength=quantization;
        }
        
    }
    console.log(parsedArray)
    resolve();
}, 1500);   
});
}




export function setSensitivity(number){
    THRESHOLD_RMS=number;
}

export const clearQuantization = () =>{
    realTimeArray=[];
}

const statisticMode = a => 
  Object.values(
    a.reduce((count, e) => {
      if (!(e in count)) {
        count[e] = [0, e];
      }
      
      count[e][0]++;
      return count;
    }, {})
  ).reduce((a, v) => v[0] < a[0] ? a : v, [0, null])[1];
;

const statisticModeWithThreshold = (arr, threshold) => {
    const count = arr.reduce((count, e) => {
      if (!(e in count)) {
        count[e] = 0;
      }
      
      count[e]++;
      return count;
    }, {});
  
    const zeroCount = count[0] || 0;
    const zeroCountPercentage = zeroCount / arr.length;
  
    if (zeroCountPercentage < threshold) {
      const filteredArr = arr.filter(e => e !== 0);
      const filteredCount = filteredArr.reduce((count, e) => {
        if (!(e in count)) {
          count[e] = 0;
        }
        
        count[e]++;
        return count;
      }, {});
      return Object.keys(filteredCount).reduce((a, v) => filteredCount[v] > filteredCount[a] ? v : a, null);
    }
  
    return Object.keys(count).reduce((a, v) => count[v] > count[a] ? v : a, null);
  };

//Fastmode
function dictateFrequency(features){
    cur_rms = features[FEATURE_NAME_RMS]
    
	if (cur_rms > THRESHOLD_RMS_FREQUENCY) {
        curRMSAboveThreshold=true
    }
    else{
        curRMSAboveThreshold=false
    }
   
}


function 
/* get new audio 
context object */
createAudioCtx(){
    let AudioContext = window.AudioContext || window.webkitAudioContext;
	return new AudioContext({
        sampleRate: 16000,
      });
}



export function getVAEDataAndUpload(){

    console.log("Original Array in fusa for VAEDataset: ")
    console.log(realTimeArray) 
    console.log("Corrected Array in samples for VAEDataset: ")
    console.log(correctedArray) 
    uploadSheetToVAEDataset(realTimeArray, correctedArray)

}


function
/* create microphone
audio input source from 
audio context */
createMicSrcFrom(audioCtx){
    /* get microphone access */
    return new Promise((resolve, reject)=>{
        /* only audio */
        let constraints = {audio: {
            sampleRate: 16000,
            channelCount: 1,
            noiseSuppression: true,
        }, video:false}
        //noiseSuppression: true,
        navigator.mediaDevices.getUserMedia(constraints)
        .then((stream)=>{
            /* create source from
            microphone input stream */
            
            let src = audioCtx.createMediaStreamSource(stream)
            resolve(src)
        }).catch((err)=>{reject(err)})
    })
}



function
/* call given function
on new microphone analyser
data */
onMicDataCall(features, callback){
    return new Promise((resolve, reject)=>{
        let audioCtx = createAudioCtx()
        createMicSrcFrom(audioCtx)
        
        .then((src) => {
            analyzer = Meyda.createMeydaAnalyzer({
                'audioContext': audioCtx,
                'source':src,
                'bufferSize':512,
                'sampleRate':16000,
                'windowingFunction':"hanning",
                'numberOfMFCCCoefficients':13,
                'inputs':1,
                'featureExtractors':features,
                'callback':callback
            })
            resolve(analyzer)
        }).catch((err)=>{
            reject(err)
        })
    })
    
}


//////////////////////////////// CODE FOR LSTM //////////////////////////////// 

//////////////////////////////// CODE FOR LSTM //////////////////////////////// 

//function encodeSequence(sequence) {
//    if (!Array.isArray(sequence)) {
//      throw new Error('Invalid sequence. Must be an array.');
//    }
//  
//    return sequence.map((word) => wordToIndex[word]);
//  }
//  
//let noisySequencesEncoded = THIS_IS_THE_INITIAL_SEQUENCE.map(encodeSequence);
//let reconstructedSequencesEncoded = reconstructedSequences.map(encodeSequence);
  

//function padSequence(sequence, maxLength) {
//    if (sequence.length < maxLength) {
//      return sequence.concat(Array(maxLength - sequence.length).fill("0"));
//    }
//    return sequence.slice(0, maxLength);
//  }
  
//  let maxLen = Math.max(
//    ...noisySequencesEncoded.map((seq) => seq.length),
//    //...reconstructedSequencesEncoded.map((seq) => seq.length)
//  );
  
//  let noisySequencesOneHot = noisySequencesEncoded.map((seq) =>
//    padSequence(seq, maxLen)
//  );
  //let reconstructedSequencesOneHot = reconstructedSequencesEncoded.map((seq) =>
  //  padSequence(seq, maxLen)
  //);

//  function toOneHot(sequence, vocabSize) {
//    let oneHot = Array(sequence.length);
//    for (let i = 0; i < sequence.length; i++) {
//      let encodedWord = sequence[i];
//      let wordVector = Array(vocabSize).fill(0);
//      wordVector[encodedWord] = 1;
//      oneHot[i] = wordVector;
//    }
//    return oneHot;
//  }
  
  //noisySequencesOneHot = noisySequencesOneHot.map((seq) =>
  //  toOneHot(seq, vocabSize)
  //);
  //reconstructedSequencesOneHot = reconstructedSequencesOneHot.map((seq) =>
  //  toOneHot(seq, vocabSize)
  //);

  //FEEED THIS TO LSTM: noisySequencesOneHot
  

  //AQUI TIENES QUE RECONSTRUIRLO
  function encodeSequence(sequence) {
    if (!Array.isArray(sequence)) {
      throw new Error('Invalid sequence. Must be an array.');
    }
  
    return sequence.map((word) => wordToIndex[word]);
  }


  function padSequence(sequence, maxLength) {
    if (!Array.isArray(sequence)) {
      sequence = Array.from(sequence);
    }
  
    if (sequence.length < maxLength) {
      return sequence.concat(Array(maxLength - sequence.length).fill("0"));
    }
  
    return sequence.slice(0, maxLength);
  }

  function toOneHot(sequence, vocabSize) {
    let oneHot = Array(sequence.length);
    for (let i = 0; i < sequence.length; i++) {
      let encodedWord = sequence[i];
      let wordVector = Array(vocabSize).fill(0);
      wordVector[encodedWord] = 1;
      oneHot[i] = wordVector;
    }
    return oneHot;
  }

function savePreviousArrayForLSTMtraining(listOfStrings,inputSize){

    const maxLen = Math.max(inputSize); // Calculate the maximum length (in this case, it will be the same as the array length)

    // Pad and convert the encoded array to one-hot vectors

    const paddedArray = padSequence(listOfStrings, maxLen);
    
    //FIRST ELEMENT OF LSTM ANALYSIS SAVING//////////////////////////////////////////////
    //++//++ let heardRealTimeArray = get(heardRealTimeArrayStore);
    //++//++ heardRealTimeArray = paddedArray
    //++//++ heardRealTimeArrayStore.update(() => {
    //++//++     return heardRealTimeArray;
    //++//++ });
    //FIRST ELEMENT OF LSTM ANALYSIS SAVING//////////////////////////////////////////////


}
//////////////////////////////// CODE FOR LSTM //////////////////////////////// 
function predictLSTM(array, inputSize) {
    let vocabSize=83
    //console.log("prediction:")
    //console.log("original array:")
    //console.log(array)
    console.log(array)
    const listOfStrings = array.map(number => {
      if (number === undefined) {
        return '0.0';
      }
      return number.toString();
    });
    //this is just for public model training
    savePreviousArrayForLSTMtraining(listOfStrings,inputSize);

    const encodedArray = encodeSequence(listOfStrings);
    const maxLen = Math.max(inputSize); // Calculate the maximum length (in this case, it will be the same as the array length)

    // Pad and convert the encoded array to one-hot vectors

    const paddedArray = padSequence(encodedArray, maxLen);
    const oneHotArray = toOneHot(paddedArray, vocabSize);
    //console.log(oneHotArray)
    let input = tf.tensor(oneHotArray).reshape([1, inputSize, vocabSize]);
    // Size(14336) must match the product of shape 256,104

	let model = get(lstmNore); 
    
    //input=input.reshape([1, 65, 13, 1])

    const prediction = model.predict(input);
    

    
  const predictionArray = Array.from(prediction.dataSync());
  const predictedIndices = [];

  for (let i = 0; i < predictionArray.length; i += vocabSize) {
    const vector = predictionArray.slice(i, i + vocabSize);
    const maxIndex = vector.indexOf(Math.max(...vector));
    predictedIndices.push(maxIndex);
  }

  const predictedWords = predictedIndices.map(index => indexToWord[index]);

  //console.log("lstm reconstructed array:")
  const listOfNumbers = predictedWords.map(string => parseFloat(string));
  //console.log(listOfNumbers);
  
  return listOfNumbers
    //console.log(prediction.dataSync()[0]);
    //console.log(prediction.dataSync()[1]);
    //console.log(prediction.dataSync()[2]);
    //console.log(prediction.dataSync()[3]);
    //console.log(prediction.dataSync()[4]);
    //console.log(prediction.dataSync()[5]);
    //console.log(prediction.dataSync()[6]);
}



//////////////////////////////// CODE FOR LSTM //////////////////////////////// 







function frequencyParser(freq){
    let note;
    let octave;
    let symbol; 
    let silence=false;
    if (freq<16.835){note="c"; octave=0; silence=true;}
    else if ((freq>16.835) && (freq<17.835)){note="db"; octave=0;}
    else if ((freq>17.835) && (freq<18.9)){note="d"; octave=0;}
    else if ((freq>18.9) && (freq<20.025)){note="eb"; octave=0;}
    else if ((freq>20.025) && (freq<21.215)){note="e"; octave=0;}
    else if ((freq>21.215) && (freq<22.475)){note="f"; octave=0;}
    else if ((freq>22.475) && (freq<23.81)){note="gb"; octave=0;}
    else if ((freq>23.81) && (freq<25.23)){note="g"; octave=0;}
    else if ((freq>25.23) && (freq<26.73)){note="ab"; octave=0;}
    else if ((freq>26.73) && (freq<28.32)){note="a"; octave=0;}
    else if ((freq>28.32) && (freq<30.005)){note="bb"; octave=0;}
    else if ((freq>30.005) && (freq<31.785)){note="b"; octave=0;}
    else if ((freq>31.785) && (freq<33.675)){note="c"; octave=1;}
    else if ((freq>33.675) && (freq<35.68)){note="db"; octave=1;}
    else if ((freq>35.68) && (freq<37.8)){note="d"; octave=1;}
    else if ((freq>37.8) && (freq<40.045)){note="eb"; octave=1;}
    else if ((freq>40.045) && (freq<42.425)){note="e"; octave=1;}
    else if ((freq>42.425) && (freq<44.95)){note="f"; octave=1;}
    else if ((freq>44.95) && (freq<47.625)){note="gb"; octave=1;}
    else if ((freq>47.625) && (freq<50.455)){note="g"; octave=1;}
    else if ((freq>50.455) && (freq<53.455)){note="ab"; octave=1;}
    else if ((freq>53.455) && (freq<56.635)){note="a"; octave=1;}
    else if ((freq>56.635) && (freq<60.005)){note="bb"; octave=1;}
    else if ((freq>60.005) && (freq<63.575)){note="b"; octave=1;}
    else if ((freq>63.575) && (freq<67.355)){note="c"; octave=2;}
    else if ((freq>67.355) && (freq<71.36)){note="db"; octave=2;}
    else if ((freq>71.36) && (freq<75.6)){note="d"; octave=2;}
    else if ((freq>75.6) && (freq<80.095)){note="eb"; octave=2;}
    else if ((freq>80.095) && (freq<84.86)){note="e"; octave=2;}
    else if ((freq>84.86) && (freq<89.905)){note="f"; octave=2;}
    else if ((freq>89.905) && (freq<95.25)){note="gb"; octave=2;}
    else if ((freq>95.25) && (freq<100.915)){note="g"; octave=2;}
    else if ((freq>100.915) && (freq<106.915)){note="ab"; octave=2;}
    else if ((freq>106.915) && (freq<113.27)){note="a"; octave=2;}
    else if ((freq>113.27) && (freq<120.005)){note="bb"; octave=2;}
    else if ((freq>120.005) && (freq<127.14)){note="b"; octave=2;}
    else if ((freq>127.14) && (freq<134.7)){note="c"; octave=3;}
    else if ((freq>134.7) && (freq<142.71)){note="db"; octave=3;}
    else if ((freq>142.71) && (freq<151.195)){note="d"; octave=3;}
    else if ((freq>151.195) && (freq<160.185)){note="eb"; octave=3;}
    else if ((freq>160.185) && (freq<169.71)){note="e"; octave=3;}
    else if ((freq>169.71) && (freq<179.805)){note="f"; octave=3;}
    else if ((freq>179.805) && (freq<190.5)){note="gb"; octave=3;}
    else if ((freq>190.5) && (freq<201.825)){note="g"; octave=3;}
    else if ((freq>201.825) && (freq<213.825)){note="ab"; octave=3;}
    else if ((freq>213.825) && (freq<226.54)){note="a"; octave=3;}
    else if ((freq>226.54) && (freq<240.01)){note="bb"; octave=3;}
    else if ((freq>240.01) && (freq<254.285)){note="b"; octave=3;}
    else if ((freq>254.285) && (freq<269.405)){note="c"; octave=4;}
    else if ((freq>269.405) && (freq<285.42)){note="db"; octave=4;}
    else if ((freq>285.42) && (freq<302.395)){note="d"; octave=4;}
    else if ((freq>302.395) && (freq<320.38)){note="eb"; octave=4;}
    else if ((freq>320.38) && (freq<339.43)){note="e"; octave=4;}
    else if ((freq>339.43) && (freq<359.61)){note="f"; octave=4;}
    else if ((freq>359.61) && (freq<380.995)){note="gb"; octave=4;}
    else if ((freq>380.995) && (freq<403.65)){note="g"; octave=4;}
    else if ((freq>403.65) && (freq<427.65)){note="ab"; octave=4;}
    else if ((freq>427.65) && (freq<453.08)){note="a"; octave=4;}
    else if ((freq>453.08) && (freq<480.02)){note="bb"; octave=4;}
    else if ((freq>480.02) && (freq<508.565)){note="b"; octave=4;}
    else if ((freq>508.565) && (freq<538.81)){note="c"; octave=5;}
    else if ((freq>538.81) && (freq<570.85)){note="db"; octave=5;}
    else if ((freq>570.85) && (freq<604.79)){note="d"; octave=5;}
    else if ((freq>604.79) && (freq<640.75)){note="eb"; octave=5;}
    else if ((freq>640.75) && (freq<678.855)){note="e"; octave=5;}
    else if ((freq>678.855) && (freq<719.225)){note="f"; octave=5;}
    else if ((freq>719.225) && (freq<761.99)){note="gb"; octave=5;}
    else if ((freq>761.99) && (freq<807.3)){note="g"; octave=5;}
    else if ((freq>807.3) && (freq<855.305)){note="ab"; octave=5;}
    else if ((freq>855.305) && (freq<906.165)){note="a"; octave=5;}
    else if ((freq>906.165) && (freq<960.05)){note="bb"; octave=5;}
    else if ((freq>960.05) && (freq<1017.135)){note="b"; octave=5;}
    else if ((freq>1017.135) && (freq<1077.615)){note="c"; octave=6;}
    else if ((freq>1077.615) && (freq<1141.695)){note="db"; octave=6;}
    else if ((freq>1141.695) && (freq<1209.585)){note="d"; octave=6;}
    else if ((freq>1209.585) && (freq<1281.51)){note="eb"; octave=6;}
    else if ((freq>1281.51) && (freq<1357.71)){note="e"; octave=6;}
    else if ((freq>1357.71) && (freq<1438.445)){note="f"; octave=6;}
    else if ((freq>1438.445) && (freq<1523.98)){note="gb"; octave=6;}
    else if ((freq>1523.98) && (freq<1614.6)){note="g"; octave=6;}
    else if ((freq>1614.6) && (freq<1710.61)){note="ab"; octave=6;}
    else if ((freq>1710.61) && (freq<1812.33)){note="a"; octave=6;}
    else if ((freq>1812.33) && (freq<1920.095)){note="bb"; octave=6;}
    else if ((freq>1920.095) && (freq<2034.265)){note="b"; octave=6;}
    else if ((freq>2034.265) && (freq<2155.23)){note="c"; octave=7;}
    else if ((freq>2155.23) && (freq<2283.39)){note="db"; octave=7;}
    else if ((freq>2283.39) && (freq<2419.17)){note="d"; octave=7;}
    else if ((freq>2419.17) && (freq<2563.02)){note="eb"; octave=7;}
    else if ((freq>2563.02) && (freq<2715.425)){note="e"; octave=7;}
    else if ((freq>2715.425) && (freq<2876.895)){note="f"; octave=7;}
    else if ((freq>2876.895) && (freq<3047.96)){note="gb"; octave=7;}
    else if ((freq>3047.96) && (freq<3229.2)){note="g"; octave=7;}
    else if ((freq>3229.2) && (freq<3421.22)){note="ab"; octave=7;}
    else if ((freq>3421.22) && (freq<3624.655)){note="a"; octave=7;}
    else if ((freq>3624.655) && (freq<3840.19)){note="bb"; octave=7;}
    else if ((freq>3840.19) && (freq<4068.54)){note="b"; octave=7;}
    else if ((freq>4068.54) && (freq<4310.465)){note="c"; octave=8;}
    else if ((freq>4310.465) && (freq<4566.775)){note="db"; octave=8;}
    else if ((freq>4566.775) && (freq<4838.33)){note="d"; octave=8;}
    else if ((freq>4838.33) && (freq<5126.035)){note="eb"; octave=8;}
    else if ((freq>5126.035) && (freq<5430.845)){note="e"; octave=8;}
    else if ((freq>5430.845) && (freq<5753.78)){note="f"; octave=8;}
    else if ((freq>5753.78) && (freq<6095.92)){note="gb"; octave=8;}
    else if ((freq>6095.92) && (freq<6458.405)){note="g"; octave=8;}
    else if ((freq>6458.405) && (freq<6842.44)){note="ab"; octave=8;}
    else if ((freq>6842.44) && (freq<7249.31)){note="a"; octave=8;}
    else if ((freq>7249.31) && (freq<7680.375)){note="bb"; octave=8;}
    else if (freq>7680.375){note="b"; octave=8;}

    if (note.charAt(0)=="a"){if (note.length==2){note="a"; symbol="b";} else{note="a";symbol="";}}
    else if (note.charAt(0)=="b"){if (note.length==2){note="b"; symbol="b";} else{note="b";symbol="";}}
    else if (note.charAt(0)=="c"){if (note.length==2){note="c"; symbol="b";} else{note="c";symbol="";}}
    else if (note.charAt(0)=="d"){if (note.length==2){note="d"; symbol="b";} else{note="d";symbol="";}}
    else if (note.charAt(0)=="e"){if (note.length==2){note="e"; symbol="b";} else{note="e";symbol="";}}
    else if (note.charAt(0)=="f"){if (note.length==2){note="f"; symbol="b";} else{note="f";symbol="";}}
    else if (note.charAt(0)=="g"){if (note.length==2){note="g"; symbol="b";} else{note="g";symbol="";}}
    return [note,symbol,octave,silence]
}


function frequencyParserToRealFreq(freq){
let realFreq;
if (freq<16.835){realFreq=16.35}
else if ((freq>16.835) && (freq<17.835)){realFreq=17.32}
else if ((freq>17.835) && (freq<18.9)){realFreq=18.35}
else if ((freq>18.9) && (freq<20.025)){realFreq=19.45}
else if ((freq>20.025) && (freq<21.215)){realFreq=20.6}
else if ((freq>21.215) && (freq<22.475)){realFreq=21.83}
else if ((freq>22.475) && (freq<23.81)){realFreq=23.12}
else if ((freq>23.81) && (freq<25.23)){realFreq=24.5}
else if ((freq>25.23) && (freq<26.73)){realFreq=25.96}
else if ((freq>26.73) && (freq<28.32)){realFreq=27.5}
else if ((freq>28.32) && (freq<30.005)){realFreq=29.14}
else if ((freq>30.005) && (freq<31.785)){realFreq=30.87}
else if ((freq>31.785) && (freq<33.675)){realFreq=32.7}
else if ((freq>33.675) && (freq<35.68)){realFreq=34.65}
else if ((freq>35.68) && (freq<37.8)){realFreq=36.71}
else if ((freq>37.8) && (freq<40.045)){realFreq=38.89}
else if ((freq>40.045) && (freq<42.425)){realFreq=41.2}
else if ((freq>42.425) && (freq<44.95)){realFreq=43.65}
else if ((freq>44.95) && (freq<47.625)){realFreq=46.25}
else if ((freq>47.625) && (freq<50.455)){realFreq=49}
else if ((freq>50.455) && (freq<53.455)){realFreq=51.91}
else if ((freq>53.455) && (freq<56.635)){realFreq=55}
else if ((freq>56.635) && (freq<60.005)){realFreq=58.27}
else if ((freq>60.005) && (freq<63.575)){realFreq=61.74}
else if ((freq>63.575) && (freq<67.355)){realFreq=65.41}
else if ((freq>67.355) && (freq<71.36)){realFreq=69.3}
else if ((freq>71.36) && (freq<75.6)){realFreq=73.42}
else if ((freq>75.6) && (freq<80.095)){realFreq=77.78}
else if ((freq>80.095) && (freq<84.86)){realFreq=82.41}
else if ((freq>84.86) && (freq<89.905)){realFreq=87.31}
else if ((freq>89.905) && (freq<95.25)){realFreq=92.5}
else if ((freq>95.25) && (freq<100.915)){realFreq=98}
else if ((freq>100.915) && (freq<106.915)){realFreq=103.83}
else if ((freq>106.915) && (freq<113.27)){realFreq=110}
else if ((freq>113.27) && (freq<120.005)){realFreq=116.54}
else if ((freq>120.005) && (freq<127.14)){realFreq=123.47}
else if ((freq>127.14) && (freq<134.7)){realFreq=130.81}
else if ((freq>134.7) && (freq<142.71)){realFreq=138.59}
else if ((freq>142.71) && (freq<151.195)){realFreq=146.83}
else if ((freq>151.195) && (freq<160.185)){realFreq=155.56}
else if ((freq>160.185) && (freq<169.71)){realFreq=164.81}
else if ((freq>169.71) && (freq<179.805)){realFreq=174.61}
else if ((freq>179.805) && (freq<190.5)){realFreq=185}
else if ((freq>190.5) && (freq<201.825)){realFreq=196}
else if ((freq>201.825) && (freq<213.825)){realFreq=207.65}
else if ((freq>213.825) && (freq<226.54)){realFreq=220}
else if ((freq>226.54) && (freq<240.01)){realFreq=233.08}
else if ((freq>240.01) && (freq<254.285)){realFreq=246.94}
else if ((freq>254.285) && (freq<269.405)){realFreq=261.63}
else if ((freq>269.405) && (freq<285.42)){realFreq=277.18}
else if ((freq>285.42) && (freq<302.395)){realFreq=293.66}
else if ((freq>302.395) && (freq<320.38)){realFreq=311.13}
else if ((freq>320.38) && (freq<339.43)){realFreq=329.63}
else if ((freq>339.43) && (freq<359.61)){realFreq=349.23}
else if ((freq>359.61) && (freq<380.995)){realFreq=369.99}
else if ((freq>380.995) && (freq<403.65)){realFreq=392}
else if ((freq>403.65) && (freq<427.65)){realFreq=415.3}
else if ((freq>427.65) && (freq<453.08)){realFreq=440}
else if ((freq>453.08) && (freq<480.02)){realFreq=466.16}
else if ((freq>480.02) && (freq<508.565)){realFreq=493.88}
else if ((freq>508.565) && (freq<538.81)){realFreq=523.25}
else if ((freq>538.81) && (freq<570.85)){realFreq=554.37}
else if ((freq>570.85) && (freq<604.79)){realFreq=587.33}
else if ((freq>604.79) && (freq<640.75)){realFreq=622.25}
else if ((freq>640.75) && (freq<678.855)){realFreq=659.25}
else if ((freq>678.855) && (freq<719.225)){realFreq=698.46}
else if ((freq>719.225) && (freq<761.99)){realFreq=739.99}
else if ((freq>761.99) && (freq<807.3)){realFreq=783.99}
else if ((freq>807.3) && (freq<855.305)){realFreq=830.61}
else if ((freq>855.305) && (freq<906.165)){realFreq=880}
else if ((freq>906.165) && (freq<960.05)){realFreq=932.33}
else if ((freq>960.05) && (freq<1017.135)){realFreq=987.77}
else if ((freq>1017.135) && (freq<1077.615)){realFreq=1046.5}
else if ((freq>1077.615) && (freq<1141.695)){realFreq=1108.73}
else if ((freq>1141.695) && (freq<1209.585)){realFreq=1174.66}
else if ((freq>1209.585) && (freq<1281.51)){realFreq=1244.51}
else if ((freq>1281.51) && (freq<1357.71)){realFreq=1318.51}
else if ((freq>1357.71) && (freq<1438.445)){realFreq=1396.91}
else if ((freq>1438.445) && (freq<1523.98)){realFreq=1479.98}
else if ((freq>1523.98) && (freq<1614.6)){realFreq=1567.98}
else if ((freq>1614.6) && (freq<1710.61)){realFreq=1661.22}
else if ((freq>1710.61) && (freq<1812.33)){realFreq=1760}
else if ((freq>1812.33) && (freq<1920.095)){realFreq=1864.66}
else if ((freq>1920.095) && (freq<2034.265)){realFreq=1975.53}
else if ((freq>2034.265) && (freq<2155.23)){realFreq=2093}
else if ((freq>2155.23) && (freq<2283.39)){realFreq=2217.46}
else if ((freq>2283.39) && (freq<2419.17)){realFreq=2349.32}
else if ((freq>2419.17) && (freq<2563.02)){realFreq=2489.02}
else if ((freq>2563.02) && (freq<2715.425)){realFreq=2637.02}
else if ((freq>2715.425) && (freq<2876.895)){realFreq=2793.83}
else if ((freq>2876.895) && (freq<3047.96)){realFreq=2959.96}
else if ((freq>3047.96) && (freq<3229.2)){realFreq=3135.96}
else if ((freq>3229.2) && (freq<3421.22)){realFreq=3322.44}
else if ((freq>3421.22) && (freq<3624.655)){realFreq=3520}
else if ((freq>3624.655) && (freq<3840.19)){realFreq=3729.31}
else if ((freq>3840.19) && (freq<4068.54)){realFreq=3951.07}
else if ((freq>4068.54) && (freq<4310.465)){realFreq=4186.01}
else if ((freq>4310.465) && (freq<4566.775)){realFreq=4434.92}
else if ((freq>4566.775) && (freq<4838.33)){realFreq=4698.63}
else if ((freq>4838.33) && (freq<5126.035)){realFreq=4978.03}
else if ((freq>5126.035) && (freq<5430.845)){realFreq=5274.04}
else if ((freq>5430.845) && (freq<5753.78)){realFreq=5587.65}
else if ((freq>5753.78) && (freq<6095.92)){realFreq=5919.91}
else if ((freq>6095.92) && (freq<6458.405)){realFreq=6271.93}
else if ((freq>6458.405) && (freq<6842.44)){realFreq=6644.88}
else if ((freq>6842.44) && (freq<7249.31)){realFreq=7040}
else if ((freq>7249.31) && (freq<7680.375)){realFreq=7458.62}
else if (freq>7680.375){realFreq=7902.13}
return realFreq;
}



    
//TODO: export function fillLastMeasure(){}


    //TODO: export function fillFirstMeasure(){}
    //the aim is to add 0 padding at the begining (then on array treatment you can modify)
    /*if(realTimeArray[0]==0){
        let counter=0;
        //search from first non zero element
        while(realTimeArray[counter]==0){
            counter++;
        }
        counter++;
        //substitution
        for(let i=0; i<counter; i++){
            realTimeArray[i]=realTimeArray[counter];
        }
    }*/
    //TODO: export function fillFirstMeasure(){}




    export function printSpeechForDebugging(quantization, applyNoreMagic, cleanPrevious){
        let realTimeArray = get(realTimeArrayStore);
        if (applyNoreMagic==false){
            //Do Nothing
            //let realTimeArray = get(realTimeArrayStore);
            
            //Norewand training step one, get raw array and raw sheet:
            storeOriginalSheetLSTM(realTimeArray);
        }else{
            let realTimeArraySt = get(realTimeArrayStore);
            realTimeArray = applyNoreWingMagic(realTimeArraySt, 384);
             //Norewand training step one, get raw array and raw sheet:
            storeAISheetLSTM(realTimeArray);
        }
    
        
        console.log("original array previous to NoreWingMagic")
        //console.log(realTimeArraySt.toString())
        console.log("NoreWingMagic applied")
        //console.log(realTimeArray.toString())
        
    
        //console.log("beatNum")
        //console.log(beatNum)
        let noteArray=[]
        let finalArray=[]
        let counterForQuantization=1;
        
        //1-basicamente recorre el numero de samples que se le pide y calcula la moda, despues lo añade al finalArray
        //slicesToMode is just used for debuggin how different heard slices are transformed into its corresponding final array
        let slicesToMode = [];
        for(let i=0; i<realTimeArray.length; i++){
             noteArray=noteArray.concat(realTimeArray[i])
    
            if(counterForQuantization==quantization){
                let mode=statisticMode(noteArray);
                //let mode=statisticModeWithThreshold(noteArray, MODE_0_THRESHOLD);
                
                finalArray=finalArray.concat(mode);
                counterForQuantization=1;
                slicesToMode.push(noteArray)
                noteArray=[] 
                //CALCULO LA MODA Y APPEND A UN ARRAY
            } 
            else{
                counterForQuantization++;
            }
        }
        console.log("slicesToFinalArray: ")
        console.log(slicesToMode) 
        console.log("finalArray: ")
        console.log(finalArray) 
        console.log("vaeArray:")
        //staveToSampleParserTest()
        
        if (cleanPrevious==true){
            cleanCanvasAndData()
        }
        
        //NOW HAVING THE ARRAY IN THE CURRENT QUANTIZATION, LETS PRINT IT ON SCREEN!!!
        //si en vez de elegir una muestra por fusa son 4: fusa 4, semicorchea 8, corchea 16, negra 32
        //currentNotelength: 1 fusa, 2 semicorchea,4 corchea, 8 negra, 16, blanca, 32 redonda
        let currentNoteLength=quantization
    
        let parsedArray=[]
    
        for(let i=0; i<finalArray.length;i++){
            parsedArray=parsedArray.concat(frequencyParser(finalArray[i]))
    
            if(finalArray[i]==finalArray[i+1]){
                currentNoteLength=currentNoteLength+quantization;
                console.log("note")
                console.log("silence")
                console.log("notelenght")
                console.log(currentNoteLength)
            }
            else{
                
                let result=frequencyParser(finalArray[i]);
                console.log("note")
                console.log(result) //aqui me lo hace bien el bemol y todo: ['si', 'b', 2, false] ESTO ES LO QUE RECIBO
                console.log("notelenght")
                console.log(currentNoteLength)
                 
                printPerfectNote(result, currentNoteLength)
    
    
                //TODO: mirar esto si cambia al montarme la funcion que me acabo de montar
                currentNoteLength=quantization;
            }
        }
        console.log(parsedArray)
    }
    

    export function stopFrequencyRecognizerRealTimeForDebugging() {
        return new Promise((resolve) => {

            notifications.green('Stopped Frequency Recognition...', 1500);
            analyzer.stop()
            audioContext.close()
            console.log("realTimeArray RAW VERSION: ")
            console.log(realTimeArray)
            window.clearTimeout(timer);
            
        
            realTimeArray=fillLastMeasure(realTimeArray);
            
            //En nuestro caso tenemos 4 muestras por fusa, para cuantizar el valor corrrespondiente sería: fusa 4, semicorchea 8, corchea 16, negra 32
            //(ahora olvidate de esto) elegir tipo de cuantización (fusa 1, semicorchea 2, corchea 4, negra 8) implica que coges de uno en un elemento del array de dos en dos...
            
            //This is optional preprocessing of an array to delete n first elements (recorder is desyncronized usually
            //this gives the option to syncronize)
            let n = 10;
            realTimeArray.splice(0, n);
            console.log(realTimeArray);
        
            realTimeArrayStore.update(() => {
                return realTimeArray;
            });
            

            printSpeechForDebugging(16, false, false);
            //printSeparation()


            setTimeout(function() {
                printSpeechForDebugging(16, true, true);
                resolve(); // Resolve the Promise when the function is done
            }, 3000);
        });
        

    }

    /*export function printSeparation(){
        addNoteWithOctave("c", 3)
        addNoteWithOctave("c", 4)
        addNoteWithOctave("c", 3)
        addNoteWithOctave("c", 4)
        addNoteWithOctave("c", 3)
        addNoteWithOctave("c", 4)
        addNoteWithOctave("c", 3)
        addNoteWithOctave("c", 4)
        addNoteWithOctave("c", 3)
    }*/